import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from 'qrcode';

import "@css/tree.css";
import { Box, Button, Dialog, Grid, Tooltip, Typography, createTheme } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import GenealogySponsor from './GenealogySponsor';
import { currencyFormat } from '@utils/Tools';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { MdDownload, MdQrCode } from 'react-icons/md';

import GeneologyDialog from '@components/GeneologyDialog';


export default function Index(props) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let { page } = useParams();
    const shareLink = useRef(null);
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const [state, setState] = useState({
        pageIndex: null,
        subPage: 'sponsor',
        totalDirectDownlines: 0,
        totalDownlines: 0,
        data: []
    });
    const { username, email } = useSelector(state => state.user);
    const [geneologyDialog, setGeneologyDialog] = useState(false);

    const handleClickOpenGeneologyDialog = () => {
        setGeneologyDialog(true);
    };
    const handleCloseGeneologyDialog = () => {
        setGeneologyDialog(false);
    };

    useEffect(() => {
        if (page) {
            if (_.includes(['sponsor', 'placement'], page)) {
                setState({
                    ...state,
                    pageIndex: page === 'placement' ? 1 : 0,
                    subPage: page,
                })
            }
        }
    }, [page]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        if (state.pageIndex !== null) {
            let apiUrl = 'sponsor-genealogy';
            if (state.pageIndex === 0) {
            } else if (state.pageIndex === 1) {
                apiUrl = 'placement-genealogy';
            }

            getUrl(apiUrl).then(response => {
                if (response.status && isMountedRef.current) {
                    setState({
                        ...state,
                        totalDirectDownlines: response.data.tree_data.total_direct_downline,
                        totalDownlines: response.data.tree_data.total_downline,
                        data: response.data.tree_data
                    });
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }

        return () => isMountedRef.current = false;
    }, [state.pageIndex]);

    return (
        <div className='p-t-100 p-lr-35' style={{ minHeight: '100vh' }}>
            {/* Self data */}
            <div className='p-t-40'>
                <div className='glass-box-hightlight' >
                    <div style={{ background: '#16114e', borderRadius: 10 }}>
                        <div className='glass-box txt-white flex-sb-m w-full' style={{ background: '#ffffff21' }}>
                            <div className='flex-c-m' style={{ width: 130 }}>
                                <img className='w-full' src={`/images/general/genealogy.png`} alt="genealogy" />
                            </div>
                            <div className='w-full p-l-15'>
                                <div className='p-b-15 w-full flex-sb-m' style={{ marginTop: -10 }}>
                                    <div className='txt-upper fs-12 p-lr-15'>
                                        <p className='p-b-5 flex-m txt-white txt_truncate1'>{username}</p>
                                        <p className='flex-m cl-label txt_truncate1'>{email}</p>
                                    </div>
                                    <div className='pointer' style={{ width: 30, minWidth: 25 }} onClick={handleClickOpenGeneologyDialog}>
                                        <img className='w-full' src={`/images/general/qr.png`} alt="genealogy" />
                                    </div>
                                </div>
                                <div className='w-full flex-sb-m'>
                                    <div className='txt-center' style={{ width: '50%' }}>
                                        <p className='fs-17'>{state.totalDirectDownlines}</p>
                                        <p className='fs-10 cl-label txt-upper p-t-5'>{t('genealogy.totalDirect')}</p>
                                    </div>
                                    <div className='txt-center' style={{ width: '50%' }}>
                                        <p className='fs-17'>{state.totalDownlines}</p>
                                        <p className='fs-10 cl-label txt-upper p-t-5'>{t('genealogy.total')}</p>
                                    </div>
                                </div>
                                <div className='w-full flex-sb-m p-t-15'>
                                    <div className='txt-center' style={{ width: '50%' }}>
                                        <p className='fs-17'>{currencyFormat(state.data?.personal_sales || 0, 2)}</p>
                                        <p className='fs-10 cl-label txt-upper p-t-5'>{t('stake.individual')}</p>
                                    </div>
                                    <div className='txt-center' style={{ width: '50%' }}>
                                        <p className='fs-17'>{currencyFormat(state.data?.direct_sales || 0, 2)}</p>
                                        <p className='fs-10 cl-label txt-upper p-t-5'>{t('stake.direct')}</p>
                                    </div>
                                </div>
                                <div className='flex-col flex-c w-full txt-center p-t-15'>
                                    <p className='fs-17'>{currencyFormat(state.data?.group_sales || 0, 2)}</p>
                                    <p className='fs-10 cl-label txt-upper p-t-5'>{t('stake.group')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='glass-shadow m-t-20'></div>
            </div>
            {/* Downline */}
            <div >
                {/* <div className="p-lr-20 flex-sb-m" style={{ gap: 8 }}>
                    <div>
                        <Typography className={styles.subline}>{t('genealogy.member')}</Typography>
                        <Typography className={styles.subline2}>{t('genealogy.memberTotal', { 'amount': state.totalDownlines })}</Typography>
                    </div>
         
                    <div>
                        <MdQrCode className='txt-white fs-35 pointer' onClick={handleClickOpenGeneologyDialog} />
                    </div>
                </div> */}
                <div>
                    <GenealogySponsor state={state} />
                </div>
            </div>
            <GeneologyDialog dialog={geneologyDialog} handleCloseDialog={handleCloseGeneologyDialog} />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    subline: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '130%',
        letterSpacing: '-0.16px',
        color: '#FFF',
        fontFamily: 'sans-serif'
    },
    subline2: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '130%',
        letterSpacing: '-0.12px',
        color: '#98A2B3',
        fontFamily: 'sans-serif'
    }
}));

const tabStyle = createTheme({
    overrides: {
        MuiTab: {
            textColorPrimary: {
                color: '#dfdfdf',
                "&$selected": {
                    color: '#dbc685',
                },
            }
        },
        PrivateTabIndicator: {
            colorPrimary: {
                backgroundColor: '#dbc685'
            }
        }
    }
})