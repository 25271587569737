import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Box, Button, Container, Link } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { storePaths } from '@utils/Tools';

export default function SplashScreen() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    let location = useLocation();

    return (
        <Box className='min-h-100vh' style={{backgroundImage:'url(/images/landing/base.png)', backgroundRepeat:'no-repeat', backgroundPosition:'center bottom', backgroundSize:'contain'}}>
            <div style={{backgroundImage:'url(/images/landing/bkg-landing.png)', backgroundRepeat:'no-repeat', backgroundPosition:'center top', backgroundSize:'contain'}}>
                <div className='p-t-40 p-b-20' style={{ width: 200, margin: '0 auto', maxWidth:'64vw', filter:'drop-shadow(0 4px 4px #0009)' }}>
                    <img src='/logo.png' alt="logo-text" className='w-full' />
                </div>
                <div style={{margin: '-52px auto 0', width: '87%'}}>
                    <img src='/images/landing/graphic-landing2.png' alt="graphic" />
                </div>
                

            </div>
            <Container maxWidth="xs" className="flex-col" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, background:'linear-gradient(180deg, rgba(18,27,62,0) 0%, rgba(18,27,62,1) 40%)' }}>
                <Box className="p-tb-30 p-lr-20 txt-center w-full">
                    <Link component={RouterLink} to={`/login`} state={{ from: storePaths(location) }}>
                        <Button variant="contained" color="primary" style={{ marginTop: 20 }} fullWidth>
                            <p className='txt-upper' style={{ fontWeight: 600 }}>{t('button.login')}</p>
                        </Button>
                    </Link>
                    <Link component={RouterLink} to={`/register`} state={{ from: storePaths(location) }}>
                        <Button variant="outlined" style={{ marginTop: 20 }} fullWidth>
                            <p className='txt-upper' style={{ fontWeight: 600 }}>{t('general.register')}</p>
                        </Button>
                    </Link>
                </Box>
            </Container>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({

}));