import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { Box, Tabs, Tab, Checkbox, Dialog, FormControlLabel, DialogContent, DialogTitle, Slide, Drawer, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TbFilter } from "react-icons/tb";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import useNotificationLoading from '@utils/useNotificationLoading';
import { currencyFormat } from '@utils/Tools';
import { makeStyles, useTheme } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { getUrl } from '@utils/ApiAction';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TransactionHistoryDialog from '@components/TransactionHistoryDialog';

import { FiX } from "react-icons/fi";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

//Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TransactionHistory(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    let { type, id } = useParams();
    let navigate = useNavigate();
    const styles = useStyles();

    const isMountedRef = useRef(null);
    const [state, setState] = useState({
        id: id,
        type: [],
        listing: [],
        page: 1,
        dataList: [],
    });

    const handleChange = (event, newValue) => {
        navigate(`../${type}/transactionHistory/${newValue}`, { replace: true })
        setState({ ...state, id: newValue, dataList: [], page: 1 });
    };

    //Dialog
    const [dialog, setDialog] = useState({
        open: false,
        data: [],
        type:type
    });

    const handleClickOpenDialog = (data) => {
        setDialog({ ...dialog, open: true, data: data});
    };

    const handleCloseDialog = () => {
        setDialog({ ...dialog, open: false, data: [] });
    };

    //Drawer
    const [drawer, setDrawer] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawer({ ...drawer, [anchor]: open });
    };


    useEffect(() => {
        isMountedRef.current = true;
        getTransaction();
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [type, id, state.id]);

    const getTransaction = (page) => {
        if (type !== null) {
            setLoading(true);
            let apiUrl = '';
            if (type == 'wallet') {
                apiUrl = `/user/wallet/${state.id}`;
            } else if (type == 'commission') {
                apiUrl = `/user/commission/${state.id}`;
            }
            let param = {
                page: page
            };
            getUrl(apiUrl, param).then(response => {
                if (response.status && isMountedRef.current) {
                    if (_.size(state.dataList) > 0) {
                        setState({ ...state, type: response.data.type, listing: response.data.listing, dataList: state.dataList.concat(response.data.listing.data), page: page });
                    } else {
                        setState({ ...state, type: response.data.type, listing: response.data.listing, dataList: response.data.listing.data });
                    }
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const detailsSwitch = (type, data) => {
        switch (type) {
            case 'WalletAction':
                switch (data.transaction_type) {
                    case 'withdraw':
                        return (
                            <div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('title.cryptoAddress')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable.details.toAddress}</p>
                                </div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.txid')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable.details.txid}</p>
                                </div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('user.status')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable?.status_display?.details[i18n.resolvedLanguage]}</p>
                                </div>
                            </div>
                        );
                    default:
                        return null;
                }
            default:
                return null;
        }
    }

    const displayStatus = (status) => {
        let img_text = '';
        switch (status) {
            case 20:
            case 40:
                img_text = 'pending';
                break;
            case 60:
                img_text = 'success';
                break;
            default:
                img_text = 'failed';
                break;
        }
        return <img src={`/images/wallet/transaction-${img_text}.png`} alt="status" className={styles.iconStyle} />;
    }

    return (
        <div className="app-header-margin" style={{ padding: 0 }}>
            <Box sx={{ width: '100%' }}>
                <Box className="p-lr-10" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {
                        _.size(state.type) > 0 ?
                            <Tabs value={state.id} onChange={handleChange} variant="scrollable" scrollButtons="auto" visibleScrollbar allowScrollButtonsMobile>
                                {
                                    _.map(state.type, (type, id) => {
                                        return (
                                            <Tab key={id} value={id} label={(_.includes(i18n.resolvedLanguage, type.name) ? type.name[i18n.resolvedLanguage] : type.name?.['en'])} {...a11yProps(id)} />
                                        )
                                    })
                                }
                            </Tabs>
                            : null
                    }
                </Box>
                {/* <div className='p-t-15 p-b-20 pointer' style={{ boxShadow: 'rgb(0 0 0 / 44%) 0px 6px 5px 0px', borderBottom: '1px solid #ffffff24' }}>
                    <div className='p-lr-24 flex-sb-m'>
                        <div>
                            <p className='txt-title' style={{ color: '#667085' }}>{t('wallet.dateRange')}</p>
                            <p className='txt-white p-t-5'><b>23/11/2023 - 1/12/2024</b></p>
                        </div>
                        <div className='fs-25 btn-filter pointer' onClick={toggleDrawer('bottom', true)} style={{ lineHeight: 0 }}>
                            <TbFilter />
                        </div>
                    </div>
                </div> */}
                <div className='p-lr-24 p-t-10 m-b-150'>

                    {
                        _.size(state.dataList) <= 0 ?

                            <div className='txt-center' style={{ paddingTop: 60 }}>
                                <img src='/images/general/no-transaction.png' alt='no-transaction' style={{ width: 180 }} loading='lazy' />
                                <p className='txt-white fs-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('table.noRecord')}</p>
                            </div>
                            :
                            <>
                                {_.map(state.dataList, (data, index) => {
                                    return (
                                        <div key={index}>
                                            <div className={index !==0? 'divider-dark': ''} style={{background:'transparent'}}></div>
                                            <div onClick={() => handleClickOpenDialog(data)} className='pointer w-full flex-sb-m p-tb-20'>
                                                <div className='fs-16 clwhite'>
                                                    <p><b>{type == 'commission' ? (_.includes(i18n.resolvedLanguage, data.bonus.name) ? data.bonus.name[i18n.resolvedLanguage] : data.bonus.name?.['en']) : data.transaction_code_display.details[i18n.resolvedLanguage]}</b>{data?.transaction_type == 'withdraw' ? displayStatus(data?.wallet_transactionable?.status || 0) : null}</p>
                                                    <p className='fs-12 p-t-5 cl-label'>{type == 'commission' ? '' : data.updated_at}</p>
                                                    {type == 'commission' ?
                                                        data?.downline_username !== null &&
                                                        <div className='flex-m' style={{ width: 260, maxWidth: '50vw' }}>
                                                            {/* <div style={{ width: 25 }} className='flex-c-m'>
                                                                <img src='/images/rank/7.png' alt="rank" />
                                                            </div> */}
                                                            <p className='p-l-5 fs-13 cl-label txt-truncate' style={{ lineHeight: 'unset' }}>{t('wallet.downlineUsername')} : {data?.downline_username}</p>
                                                        </div>
                                                        :
                                                        null}
                                                </div>
                                                <div className='fs-13 p-t-8' style={{ color: '#B6B8C3' }}>
                                                    {/* <p style={{ wordBreak: 'break-all', width: '50%' }}>{t('wallet.transactionId')}: {type == 'wallet' ? data.ulid : data.id}</p> */}
                                                    <p style={{ color: data?.factor === -1 ? '#5de1e6' : '#CB6CE6', }}><b>{data?.factor === -1 ? '- ' : '+ '}$ {currencyFormat((data.amount * 1), 2)}</b></p>
                                                    <p className='txt-right cl-label'>{type == 'commission' ? data.bonus_date : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {
                                    state.listing.total > state.listing.to ?
                                        <div className='p-t-15 txt-upper txt-center'>
                                            <p className='txt-white pointer' onClick={() => getTransaction(state.page + 1)}>{t('general.loadMore')}</p>
                                        </div>
                                        : null
                                }
                            </>
                    }
                </div>
            </Box>
            <TransactionHistoryDialog dialog={dialog}  handleCloseDialog={handleCloseDialog} />
            {/* <Dialog
                open={dialog.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle className='w-full fs-15 txt-center txt-upper' style={{ borderBottom: '2px dashed #fff3', fontWeight: 'bold', paddingBottom: 20, lineHeight: 1.1 }}>
                    {type == 'commission' ? (_.includes(i18n.resolvedLanguage, dialog.data?.bonus?.name) ? dialog.data.bonus.name[i18n.resolvedLanguage] : dialog.data?.bonus?.name?.['en']) : dialog.data?.transaction_code_display?.details[i18n.resolvedLanguage]}
                    <br />
                    <span className='fs-18 txt-title p-t-10' style={{ fontWeight: 'normal', color: '#9ba9c7' }}>{t('wallet.transactionDetails')}</span>
                </DialogTitle>
                <DialogContent className='txt-white fs-14 lh-12 w-full'>
                    <div className='p-tb-15'>
                        <div className='p-b-20'>
                            <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.transactionId')}:</p>
                            <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{type == 'wallet' ? dialog.data.ulid : dialog.data.id}</p>
                        </div>
                        {
                            type == 'wallet' ?
                                <>{detailsSwitch(dialog.data?.transactionable_type, dialog.data)}</>
                                :
                                <>
                                    {
                                        (type == 'commission' && _.includes(['roi-daily-bonus', 'roi-monthly-bonus'], dialog?.data?.bonus?.bonus_type)) ?
                                            <div className='p-b-20'>
                                                <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.package')}</p>
                                                <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{_.includes(i18n.resolvedLanguage, JSON.parse(dialog.data?.plan_name)) ? JSON.parse(dialog.data?.plan_name)[i18n.resolvedLanguage] : JSON.parse(dialog.data?.plan_name)?.['en']}{' (' + dialog.data?.plan_history_id + ')'}</p>
                                            </div>
                                            :
                                            <div className='p-b-20'>
                                                <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.downlineUsername')}</p>
                                                <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{dialog.data?.downline_username}</p>
                                            </div>
                                    }
                                </>
                        }
                        <div className='p-b-20'>
                            <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.dateTime')}</p>
                            <p className='fs-15' style={{ fontWeight: 'bold' }}>{type == 'commission' ? dialog?.data?.bonus_date : dialog?.data?.updated_at}</p>
                        </div>
                    </div>
                    <div className='p-t-20 flex-sb-m' style={{ borderTop: '2px dashed #fff3' }}>
                        <p className='p-b-5 txt-upper' style={{ color: '#fff', whiteSpace: 'break-spaces' }}>{t('wallet.totalAmount')}</p>
                        <p style={{ fontWeight: 'bold', wordBreak: 'break-all', textAlign: 'right' }} className='fs-21'>USD <br /> {currencyFormat((dialog.data?.amount || 0) * 1, 4)}</p>
                    </div>
                </DialogContent>
            </Dialog> */}
            {/* Filter  -- Begin */}
            <div>
                <React.Fragment>
                    <ThemeProvider theme={drawerStyle}>
                        <Drawer
                            anchor={'bottom'}
                            open={drawer['bottom']}
                            onClose={toggleDrawer('bottom', false)}
                            style={{ position: 'relative' }}
                        >
                            <div
                                className='p-t-30 p-b-90 p-lr-24'
                                role="presentation"
                            // onKeyDown={toggleDrawer('bottom', false)}
                            >
                                <div className='flex-sb-m p-b-30'>
                                    <p><b>{t('wallet.filter')}</b></p>
                                    <p onClick={toggleDrawer('bottom', false)}><FiX /></p>
                                </div>
                                <div className='p-b-20'>
                                    <p className='p-b-14'><b>{t('wallet.byDate')}</b></p>
                                    <div className='p-tb-7' style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <FormControlLabel control={<Checkbox />} label={t('wallet.anytime')} />
                                    </div>
                                    <div className='p-tb-7' style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <FormControlLabel control={<Checkbox />} label={t('wallet.thisWeek')} />
                                    </div>
                                    <div className='p-tb-7' style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <FormControlLabel control={<Checkbox />} label={t('wallet.pass30Days')} />
                                    </div>
                                    <div className='p-tb-7' style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <FormControlLabel control={<Checkbox />} label={t('wallet.pass90Days')} />
                                    </div>
                                    <div className='p-t-7 p-b-20' style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <FormControlLabel control={<Checkbox />} label={t('wallet.dateRange')} />
                                        <div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >

                                                <div className='flex-sb-m'>
                                                    <div style={{ width: '48%' }}>
                                                        <DatePicker
                                                            sx={{
                                                                '.MuiIconButton-root': {
                                                                    color: '#222'
                                                                },
                                                                'MuiTextField-root': {
                                                                    width: '48%'
                                                                }
                                                            }} defaultValue={dayjs('2022-04-17')} />
                                                    </div>
                                                    <div style={{ width: '48%' }}>
                                                        <DatePicker sx={{
                                                            '.MuiIconButton-root': {
                                                                color: '#222'
                                                            },
                                                            'MuiTextField-root': {
                                                                width: '48%'
                                                            }
                                                        }} defaultValue={dayjs('2022-04-17')} />
                                                    </div>
                                                </div>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-b-20 p-t-15'>
                                    <p className='p-b-14'><b>{t('wallet.transactionType')}</b></p>
                                    <div className='p-tb-7' style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <FormControlLabel control={<Checkbox />} label={t('wallet.all')} />
                                    </div>
                                    <div className='p-tb-7' style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <FormControlLabel control={<Checkbox />} label={t('wallet.deposit')} />
                                    </div>
                                    <div className='p-tb-7' style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <FormControlLabel control={<Checkbox />} label={t('wallet.withdraw')} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex-sb-m p-lr-24 p-b-30 pos-fixed' style={{ bottom: 0, width: 444, maxWidth: '100%', background: 'linear-gradient(0deg, #fff, #ffffffba)', paddingTop: 20 }}>
                                <Button fullWidth variant="outlined" className='p-t-15' style={{ maxWidth: '45%' }}>{t('button.reset')}</Button>
                                <Button fullWidth variant="contained" className='p-t-15' style={{ maxWidth: '45%' }}>{t('button.apply')}</Button>
                            </div>
                        </Drawer>
                    </ThemeProvider>
                </React.Fragment>
            </div>
            {/* Filter  -- End */}
        </div>
    )
}

const drawerStyle = createTheme({
    palette: {
        MuiTypography: {
            root: {
                color: '#222!important',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    iconStyle: {
        height: 15,
        width: 14,
        marginLeft: 10
    }
}));
