import { Button, Grid, Box, Link, Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';


import { BiDollarCircle } from "react-icons/bi";
import { MdAddCard, MdCurrencyExchange, MdOutlineVideogameAsset, MdSend } from "react-icons/md";
import { storePaths } from '@utils/Tools';

import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { FiEye, FiChevronRight } from 'react-icons/fi';
import { HiMiniArrowUpCircle } from "react-icons/hi2";

import 'swiper/css/bundle';
import TransactionHistoryDialog from '@components/TransactionHistoryDialog';

const BONUS_COLOUR = {
    2: 'bkg-matching ',
    1: 'bkg-roi'
}

const BONUS_SHADOW = {
    2: 'shadow-matching ',
    1: 'shadow-roi'
}

const BONUS_ICON = {
    2: 'matching.png',
    1: 'roi.png'
}

export default function CommissionListing() {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({commissionIndex:0, pageIndex:1,  commissions:[]});
    const [slideView, setSlideView] = useState(0);
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [dialog, setDialog] = useState({open:false, data:[], type:"commission"});

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        if (state.pageIndex !== null) {
            getUrl('/user/commissions').then(response => {
                if (response.status && isMountedRef.current) {
                    if (state.pageIndex === 0) {
                        setState({ ...state, wallets: Object.values(response.data.wallet_list), walletSetting: response.data.wallet_setting });
                    } else if (state.pageIndex === 1) {
                        setState({ ...state, commissions: Object.values(response.data.comm) });
                    }
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [state.pageIndex]);

    //Dialog
    const displayStatus = (status) => {
        let img_text = '';
        switch (status) {
            case 20:
            case 40:
                img_text = 'pending';
                break;
            case 60:
                img_text = 'success';
                break;
            default:
                img_text = 'failed';
                break;
        }
        return <img src={`/images/wallet/transaction-${img_text}.png`} alt="status" className={styles.iconStyle} />;
    }
    const handleClickOpenDialog = (data) => {
        setDialog({ ...dialog, open: true, data: data });
    };

    const handleCloseDialog = () => {
        setDialog({ ...dialog, open: false, data: [] });
    };

    

    return (
        <div>
        <div className='w-full p-lr-35 p-b-10'>
            <div className='w-full'>
                <Swiper
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    style={{paddingBottom:40}}
                    onSlideChange={(e) => setState({ ...state, commissionIndex: e.activeIndex })}
                >
                    {
                        _.size(state.commissions) > 0 && _.map(state.commissions, (detail, index) => {
                            return (<div>
                                <SwiperSlide key={index}>
                                    <div className="flex-c-m flex-col">
                                        <p className='txt-white fs-12 txt-upper inter-normal p-t-5'>{detail.name[i18n.resolvedLanguage]}</p>
                                    </div>
                                    <div className='p-t-40'>
                                    <div className='pos-relative w-full flex-c-m'>
                                    <div className={`${BONUS_COLOUR[detail.id]}`} style={{width: 280, height:122, transform: 'rotate(-7.433deg)', maxWidth: '70%', marginLeft:-22, boxShadow:'none'}}>
                                    </div>
                                        <div className=' pos-absolute' style={{width: 300, maxWidth: '80%', margin: '0 auto', margin:'0 auto'}} >
                                            <div className='glass-box txt-white flex-sb-m p-b-60 p-t-30' style={{boxShadow:'rgba(0, 0, 0, 0.133) -7px -2px 3px 0px, rgba(255, 255, 255, 0.47) 2px 3px 2px 0px inset, rgba(255, 255, 255, 0.47) -2px -2px 2px 0px inset'}}>
                                                <div style={{ textShadow: '1px 1px 1px #0000003b' }}>
                                                    <p className='txt-upper fs-13 p-b-5'>{detail.name[i18n.resolvedLanguage]}
                                                        {
                                                            state.walletDecimal === 2 ?
                                                                <FiEye className='fs-12 m-l-10 pointer' onClick={() => setState({ ...state, walletDecimal: 4 })} />
                                                                :
                                                                <FiEye className='fs-12 m-l-10 pointer' onClick={() => setState({ ...state, walletDecimal: 2 })} />
                                                        }
                                                    </p>
                                                    <p className='fs-20'><b>{currencyFormat(detail.total_amount * 1, state.walletDecimal)}</b></p>
                                                </div>
                                                <div style={{ opacity: 0.5 }}>
                                                    <img src={`/images/bonus/${BONUS_ICON[detail.id]}`} alt='wallet' style={{ width: 50, filter: ' drop-shadow(rgba(0, 0, 0, 0.4) 0px 1px 0px)' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='glass-box txt-white flex-sa-m' style={{boxShadow:'rgba(0, 0, 0, 0.25) 0px 5px 4px, rgba(0, 0, 0, 0.02) 0px -4px 9px 0px, inset 0px 1px 1px 0 #ffffff73, inset -1px -1px 1px 0 #ffffff73', marginTop: 30, position: 'relative', zIndex: 2, backdropFilter: 'blur(20px)', textShadow: '1px 1px 1px #0000003b'}}>
                                        <div className='txt-center' style={{width:'calc(50% - 2px)'}}>
                                            <p>{currencyFormat(detail.week?.this_week)}</p>
                                            <p className='txt-upper fs-10 p-t-3'>{t('wallet.thisWeek')}</p>
                                        </div>
                                        <div style={{ borderRadius: 20, opacity: 0.3, width: 4, height: 25, background: '#D9D9D9', boxShadow: 'rgb(0, 0, 0) 1.5px 0 1px 0px inset' }}></div>
                                        <div className='txt-center' style={{width:'calc(50% - 2px)'}}>
                                        <p className='flex-c-m'>{currencyFormat(detail.week?.last_week)}<span className='p-l-5 flex-m fs-13' style={{fontWeight:500}}><HiMiniArrowUpCircle className='m-r-5'/> {currencyFormat((parseFloat(detail.week?.this_week) - parseFloat(detail.week?.last_week) / parseFloat(detail.week?.last_week) * 100)>0 ? (parseFloat(detail.week?.this_week) - parseFloat(detail.week?.last_week) / parseFloat(detail.week?.last_week) * 100): 0 || 0)}%</span></p>
                                            <p className='txt-upper fs-10 p-t-3'>{t('wallet.lastWeek')}</p>
                                        </div>
                                    </div>
                                    </div>
                                </SwiperSlide>
                                
                            </div>
                            )
                        })
                    }
                </Swiper>
            </div>
            {/* <div className='glass-box txt-white w-full flex-sa-m' style={{boxShadow:'rgba(0, 0, 0, 0.25) 0px 5px 4px, rgba(0, 0, 0, 0.02) 0px -4px 9px 0px, inset 0px 1px 1px 0 #ffffff73, inset -1px -1px 1px 0 #ffffff73', marginTop: -80, position: 'relative', zIndex: 2, backdropFilter: 'blur(20px)', textShadow: '1px 1px 1px #0000003b'}}>
                <div className='txt-center' style={{width:'calc(50% - 2px)'}}>
                    <p>{currencyFormat(detail.week?.this_week)}</p>
                    <p className='txt-upper fs-10 p-t-3'>{t('wallet.thisWeek')}</p>
                </div>
                <div style={{ borderRadius: 20, opacity: 0.3, width: 4, height: 25, background: '#D9D9D9', boxShadow: 'rgb(0, 0, 0) 1.5px 0 1px 0px inset' }}></div>
                <div className='txt-center' style={{width:'calc(50% - 2px)'}}>
                <p className='flex-c-m'>5,154.00<span className='p-l-5 flex-m fs-13' style={{fontWeight:500}}><HiMiniArrowUpCircle className='m-r-5'/> 65%</span></p>
                    <p className='txt-upper fs-10 p-t-3'>{t('wallet.lastWeek')}</p>
                </div>
            </div> */}
        </div>
        

        {/* //////////////////////////       Transaction History       ////////////////////////////////////// */}
            <div className='textfield-border'>
                <div className='textfield-bkg'>
                    <div className='flex-sb-m p-tb-20'>
                        <div>
                            <p className='txt-white'>{t('wallet.transactionHistory')}</p>
                        </div>
                        <Link component={RouterLink} to={state.pageIndex === 0 ? `/wallet/transactionHistory/${state.wallets[state.walletIndex]?.id}` : `/commission/transactionHistory/${state.commissions[state.commissionIndex]?.id}`} state={{ from: storePaths(location) }} underline='none'>
                            <div>
                                <p className='txt-white pointer'>{t('general.viewAll')}</p>
                            </div>
                        </Link>
                    </div>
                    {
                        _.size(state?.commissions[state.commissionIndex]?.transaction) > 0 ?
                            <>
                                {
                                    _.map(state.commissions[state.commissionIndex].transaction, (detail, index) => {
                                        return (
                                            <>
                                                <div key={index}  className="pointer p-tb-15 flex-sb-m" onClick={() => handleClickOpenDialog(detail)} >
                                                    <div className='txt-white'>
                                                        <p className='fs-16' style={{ fontWeight: 600 }}>{detail?.bonus.name[i18n.resolvedLanguage]}</p>
                                                        { detail?.downline_username!==null &&
                                                        <div className='flex-m' style={{width: 260, maxWidth: '50vw'}}>
                                                            {/* <div style={{ width: 25 }} className='flex-c-m'>
                                                                <img src='/images/rank/7.png' alt="rank" />
                                                            </div> */}
                                                            <p className='p-l-5 fs-13 cl-label txt-truncate' style={{lineHeight:'unset'}}>{t('wallet.downlineUsername')} : {detail?.downline_username}</p>
                                                        </div> }
                                                    </div>
                                                    <div className='txt-white txt-right' style={{width:100}}>
                                                        <p className='fs-16' style={{ fontWeight: 600 }}>{'+ '}{currencyFormat(detail.amount * 1, state.walletDecimal)}</p>
                                                        <p className='p-l-5 p-t-3 fs-12 cl-label '>{detail.bonus_date}</p>
                                                    </div>
                                                </div>
                                                <div className='divider-dark'></div>
                                            </>
                                        )
                                    })
                                }
                                {
                                    _.size(state?.commissions[state.commissionIndex]?.transaction) >= 10 ?
                                        <Link component={RouterLink} to={`/commission/transactionHistory/${state.commissions[state.commissionIndex].id}`} state={{ from: storePaths(location) }} underline='none' style={{ textAlign: 'center' }}>
                                            <div className='p-t-15 txt-upper'>
                                                <p className='txt-white pointer'>{t('general.viewAll')}</p>
                                            </div>
                                        </Link> : null
                                }
                            </>
                            :
                            <div className='txt-center' style={{ paddingTop: 40 }}>
                                <img src='/images/general/no-transaction.png' alt='no-transaction' style={{ width: 180 }} loading='lazy' />
                                <p className='txt-white fs-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('table.noRecord')}</p>
                            </div>
                    }
                  
                </div>
            </div>
            <TransactionHistoryDialog dialog={dialog}  handleCloseDialog={handleCloseDialog} />
        </div>
    )
}

const useStyles = makeStyles(theme => ({

}));