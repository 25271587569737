import { Box, Button, FormControl, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';
import _ from 'lodash';

import { FiEye, FiEyeOff, FiLock, FiMessageSquare, FiUser } from 'react-icons/fi';
import { BiCoinStack } from "react-icons/bi";
import { IoWalletOutline } from 'react-icons/io5';

const CARD_COLOUR = {
    1: 'card-stake-2',
    2: 'card-stake',
    3: 'card-stake-2'
}

const COIN = {
    1: 'coin-a.png',
    2: 'coin-w.png',
    3: 'coin-a.png'
}

export default function Transfer(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();

    const [inputErrors, setInputErrors] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [state, setState] = useState({
        rule: [],
        username: '',
        to_wallet_id: 0,
        amount: '',
        remark: "",
        securityPassword: ""
    });
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });
    const [walletListing, setWalletListing] = useState({});

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setLoading(true);
        getUrl(`/wallets/setting/transfer/${id}`).then(response => {
            if (response.status) {
                setState({ ...state, wallet: response.data.balance, rule: response.data.rule });
                setWalletListing(response.data.wallet_listing);
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [refresh]);

    const transfer = (event) => {
        setLoading(true);
        event.preventDefault();
        setInputErrors();
        let params = {
            from_wallet_id: id,
            to_wallet_id: state.to_wallet_id,
            username: state.username,
            amount: state.amount,
            remark: state.remark,
            security_password: state.securityPassword,
        }
        postUrl(`/wallets/transfer`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, username: '', amount: '', remark: '', securityPassword: '' });
                addAlert('', t('success.transferSuccess'), 'success', '');
                setRefresh(!refresh);
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.transferError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <div className='mobile-lr p-b-30'>
                <div className='p-t-37 pos-relative'>
                    <div className={`p-lr-15 p-tb-30 pos-relative ${CARD_COLOUR[id]}`}>
                        <p className='txt-right txt-white pos-absolute' style={{ right: 20, top: 20 }}>{state.wallet?.name && state.wallet?.name[i18n.resolvedLanguage] ? state.wallet?.name[i18n.resolvedLanguage] : '-'}</p>
                        <div className='flex-c-m' style={{ backgroundImage: 'url(/images/stake/logo-texture.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain', minHeight: 110 }}>
                            <div className='txt-white txt-center'>
                                <p className='txt-upper fs-12'>{t('withdrawal.balance')}</p>
                                <p className='p-t-10 fs-25'><b>{currencyFormat(parseFloat(state?.wallet?.balance || 0), 2)}</b></p>
                            </div>
                        </div>
                        <div className='card-hightlight pos-absolute' style={{ top: 0 }}></div>
                        <div className='card-hightlight pos-absolute' style={{ bottom: 0 }}></div>
                    </div>
                    <div className='pos-absolute' style={{ width: 65, bottom: -27, left: 8, transform: 'rotate(326deg)', filter: 'drop-shadow(2px 4px 2px #0002)', }}>
                        <img src={`/images/wallet/${COIN[id]}`} alt='coin' />
                    </div>
                    <div className='pos-absolute' style={{ width: 57, bottom: -10, right: 8, transform: 'rotate(44deg)', filter: 'drop-shadow(rgba(0, 0, 0, 0.133) 2px 4px 2px)' }}>
                        <img src={`/images/wallet/${COIN[id]}`} alt='coin' />
                    </div>
                </div>

                <div className='p-t-30'>
                    <div className='card-shadow' />
                </div>
            </div>
            {/* //////////////////////////////      Form      ////////////////////////////////// */}
            <div className='textfield-border'>
            <div className='textfield-bkg'>
                <p className='p-t-35 fs-21 txt-white' style={{ fontWeight: 'bold' }}>
                    {t('wallet.transfer')}
                </p>
                <Box className='p-b-120' component="form" onSubmit={transfer}>
                    <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('user.username')}
                            type="text"
                            color='white'
                            name='username'
                            value={state.username}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiUser className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div>
                        <FormControl fullWidth error={inputErrors && inputErrors.to_wallet_id ? true : false}>
                            <Select
                                value={state?.to_wallet_id || 0}
                                name="to_wallet_id"
                                onChange={handleChange}
                                displayEmpty
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IoWalletOutline className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')}`} --- </MenuItem>
                                {
                                    _.map(walletListing, (data, key) => {
                                        return <MenuItem key={key} value={data.id}>{data?.name[i18n.language] || data?.name?.en}</MenuItem>
                                    })
                                }
                            </Select>
                            {
                                inputErrors && inputErrors?.to_wallet_id &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.to_wallet_id}</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('gameTopup.amount')}
                            type="text"
                            color='white'
                            name='amount'
                            value={state.amount}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                            error={inputErrors && inputErrors.amount ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <BiCoinStack className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('wallet.remark')}
                            type="text"
                            color='white'
                            name='remark'
                            value={state.remark}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                            error={inputErrors && inputErrors.remark ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiMessageSquare className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('user.securityPassword')}
                            type={showPassword.securityPassword ? 'text' : 'password'}
                            color='white'
                            value={state.securityPassword}
                            name='securityPassword'
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                            error={inputErrors && inputErrors.security_password ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('securityPassword')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.securityPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    {/* <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('user.2FA')}
                            type='text'
                            color='white'
                            value={state.code}
                            onChange={({ target }) => setState({ ...state, code: target.value })}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiShield className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),

                            }}
                        />
                    </div> */}
                    <div className='p-t-30'>
                        <Button variant="contained" type="submit" fullWidth ><p style={{ fontWeight: 600 }}>{t('wallet.transfer')}</p></Button>
                    </div>
                </Box>
            </div>
            </div>
        </>
    )
}

const useStyles = makeStyles(theme => ({

}));