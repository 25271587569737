
import React, { useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Dialog, Slide, Box } from '@mui/material';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { FiX } from "react-icons/fi";
import { QRCodeSVG } from 'qrcode.react';
import QRCode from 'qrcode';
import { MdDownload, MdQrCode } from 'react-icons/md';


// const QrImageDownload = ({ imageUrl, qrValue, qrSize = 112 }) => {
//     const canvasRef = useRef(null);

//     useEffect(() => {
//         const generateQR = async () => {
//             const canvas = canvasRef.current;
//             const context = canvas.getContext('2d');

//             // Create a temporary canvas for QR code generation
//             const qrCanvas = document.createElement('canvas');
//             const qrContext = qrCanvas.getContext('2d');

//             // Set dimensions for the QR code canvas
//             qrCanvas.width = qrSize;
//             qrCanvas.height = qrSize;

//             // Load the base image
//             const image = new Image();
//             image.src = imageUrl;
//             image.crossOrigin = "Anonymous"; // Handle CORS if necessary
//             image.onload = () => {
//                 // Draw the base image on the main canvas
//                 context.drawImage(image, 0, 0, canvas.width, canvas.height);

//                 // Generate the QR code on the temporary canvas
//                 QRCode.toCanvas(qrCanvas, qrValue, {
//                     width: qrSize,
//                     height: qrSize,
//                     margin: 0,
//                     errorCorrectionLevel: 'H',
//                     color: {
//                         // dark: '#00F',  // Blue dots
//                         light: '#0000' // Transparent background
//                     }
//                 }, (error) => {
//                     if (error) console.error(error);

//                     // Calculate the QR code's position on the main canvas
//                     const qrX = (canvas.width - qrSize) / 2 + 59; // 59 pixels from the center left
//                     const qrY = (canvas.height - qrSize) / 2 + 28; // 28 pixels from the center top

//                     // Draw the temporary QR code canvas onto the main canvas at the specified position
//                     context.drawImage(qrCanvas, qrX, qrY);
//                 });
//             };
//         };

//         generateQR();
//     }, [imageUrl, qrValue, qrSize]);

//     const downloadImage = () => {
//         const canvas = canvasRef.current;
//         const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
//         const link = document.createElement('a');
//         link.href = image;
//         link.download = 'qr-invite.png'; // Filename for the downloaded image
//         document.body.appendChild(link); // Required for Firefox
//         link.click();
//         document.body.removeChild(link);
//     };

//     return (
//         <div>
//             <canvas ref={canvasRef} width="1000" height="1000" style={{ display: 'none' }} hidden></canvas>
//             <IconButton color="primary" onClick={downloadImage} aria-label="download qr image">
//                 <MdDownload />
//             </IconButton>
//         </div>
//     );
// };

const GeoneologyDialog = props => {
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const shareLink = useRef(null);
    const { dialog, handleCloseDialog } = props;

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const { username } = useSelector(state => state.user);

        //QRCode
        const copyShareLink = useCallback((e) => {
            shareLink.current.select();
            document.execCommand('copy');
            e.target.focus();
    
            addAlert('', t('general.copied'), "success");
        }, []);


    return (
        <>
           <Dialog
                open={dialog}
                onClose={handleCloseDialog} 
            >
                <div className='gradient-theme pos-relative dialog-frame'>
                    {/* Triangle Edge Design */}
                    <div className='txt-white'>
                        <img src='/images/general/triangle.png' alt='element' style={{width:20, top:10, left:10}} className='pos-absolute'/>
                        <img src='/images/general/triangle.png' alt='element' style={{width:20, bottom:10, left:10, transform: 'scaleY(-1)'}} className='pos-absolute'/>
                        <img src='/images/general/triangle.png' alt='element' style={{width:20, bottom:10, right:10, transform: 'scale(-1)'}} className='pos-absolute'/>
                    </div>

                    {/* Button Close */}
                    <div className='pos-absolute flex-c-m dialog-btn-close-outer pointer'  onClick={handleCloseDialog}>
                        <div className='flex-c-m dialog-btn-close-middle'>
                            <div className='bgwhite flex-c-m dialog-btn-close-inner'>
                            <FiX className='fs-23 clblack'/>
                            </div>
                        </div>
                    </div>

                    {/* Dialog Content */}
                    <div className='dialog-content-outer-bkg'>
                        <div className='p-tb-50 dialog-content-bkg'>
                            <p className='fs-21 txt-upper txt-center clwhite'><b>{username}</b></p>
                            <Box className='w-full'>
                            {/* <QRCode value={qrUrl} renderAs="svg" fgColor={theme.palette.primary.main} /> */}
                                <Box display="flex" flexDirection="column" width="100%" alignItems="center" justifyContent="center" className="p-lr-20 p-b-40 p-t-10">
                                    <div className='p-all-10 bgwhite' style={{ borderRadius: 15 }}>
                                        <QRCodeSVG value={`${process.env.REACT_APP_LOCAL_URL}/register?referral=${username}`} />
                                    </div>
                                    {/* <QrImageDownload imageUrl={`/images/qrbg-${i18n.language === "cn" ? "cn" : "en"}.png`} qrValue={showQr.url} qrSize={112} /> */}
                                </Box>
                                <div className='p-lr-15'>
                                    <form>
                                        <textarea ref={shareLink} value={`${process.env.REACT_APP_LOCAL_URL}/register?referral=${username}`} readOnly style={{ border: 'none', background: 'transparent', resize: 'none', outline: 'none', fontSize: 13 }} className='w-full txt-center clwhite' />
                                    </form>
                                    {document.queryCommandSupported('copy') &&
                                        <div>
                                            <div className='pointer button-large' variant="contained" onClick={copyShareLink}>{t('general.copy')}</div>
                                        </div>
                                    }
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
            </Dialog>
            </>
    )
}

export default GeoneologyDialog;