import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { Box, Button, Grid, Link } from '@mui/material';

import { QRCodeSVG } from 'qrcode.react';
import { MdOutlineInfo } from "react-icons/md";


export default function Deposit() {
    const { t } = useTranslation();
    const styles = useStyles();
    const { addAlert, loading, setLoading } = useNotificationLoading();

    const [state, setState] = useState({
        address: [],
        pageIndex: 'bsc',
        rules: [],
    });

    useEffect(() => {
        setLoading(true);
        getUrl('/wallets/deposit_address').then(response => {
            if (response.status) {
                setState({ ...state, address: response.data.bicpay, rules: response.rules });
            }else{
                addAlert('', t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const copyShareLink = useCallback((v) => {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = v;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                addAlert('', t('general.copied'), "success");
            }
            catch (e) {
                document.body.removeChild(textarea);
                addAlert('', t('general.copyFailed') + ": " + e, "warning");
            }
        } else if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
            navigator.clipboard.writeText(v).then(() => {
                addAlert('', t('general.copied'), "success");
            }).catch((error) => {
                addAlert('', t('general.copyFailed') + ": " + error, "warning");
            });
        }
        else {
            addAlert('', "Copy is not supported by browser", "error");
        }
    }, []);

    return (
        <Box className="app-header-margin">
            {
                !loading ?
                    <Grid container display='flex' flexDirection='column' alignContent='center' justifyContent='center' textAlign='center'>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                                {
                                    _.map(state.address, (data, network) => {
                                        return (
                                            <Button
                                                key={network}
                                                value={network}
                                                onClick={() => setState({ ...state, pageIndex: network })}
                                                className={state.pageIndex === network ? styles.selectedButton : styles.button}
                                                size="small"
                                                variant="contained"
                                            >
                                                <p className='fs-14 inter-normal' style={{ fontWeight: 600 }}>{network.toUpperCase()}</p>
                                            </Button>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: 40}}>
                                {
                                    state.pageIndex && _.size(state.address) > 0 ?
                                        <>
                                            <div className='p-all-20 bgwhite' style={{ borderRadius: '8px', width: 'fit-content', margin:'0 auto' }}>
                                                <QRCodeSVG value={state.address?.[state.pageIndex].address} />
                                            </div>
                                            <div className='p-t-24 max-w-full' style={{ width:300, margin:'0 auto'  }}>
                                                <div style={{ padding: '12px 16px', borderRadius: '6px', alignItems: 'center' }}>
                                                    <p className='txt-white lh-12 p-b-20' style={{ wordBreak: 'break-all' }} >{state.address[state.pageIndex].address}</p>
                                                    <Button variant='contained' onClick={() => copyShareLink(state.address[state.pageIndex].address)}>
                                                    {t('general.copy')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                        : null
                                }
                            </Grid>
                            <div className='p-t-5 p-lr-30 txt-center flex-c-m w-full' style={{  color: '#60667E' }}>
                                <p><MdOutlineInfo style={{ marginRight: 8 }} />{t('wallet.minimumFund') + state.rules?.[0]?.min_amount}</p>
                            </div>
                        </Grid>
                    </Grid>
                    : null
            }
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    selectedButton: {
        color: '#05122D',
        padding: '8px 12px 8px 12px',
        minWidth: 0,
        width: '150px',
        borderRadius: 48,
        background: '#5CE1E6',
        boxShadow: '0px 8px 10px 0px rgba(117, 238, 255, 0.24), 0px -4px 7px 0px rgba(27, 143, 150, 0.15) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.45) inset'
    },
    button: {
        borderRadius: '50px',
        color: '#5CE1E6',
        backgroundColor: '#05122D',
        background: '#05122D',
        padding: '8px 12px 8px 12px',
        minWidth: 0,
        width: '150px',
        border:'2px solid #5CE1E6',
        boxShadow:'none'
    },
}));