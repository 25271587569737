import React, { useState } from 'react';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useTranslation } from 'react-i18next';

// MUI
import { Box, Button, InputAdornment, TextField } from '@mui/material';

import { FiMail, FiMessageSquare, FiUser } from 'react-icons/fi';

import { makeStyles, useTheme } from '@mui/styles';

export default function ContactUs() {
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();

    const [state, setState] = useState({
        email: '',
        username: '',
        subject: '',
        message: '',
    });
    const [wordCount, setWordCount] = useState(0);
    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState(prevState => {
            if (name === 'message') {
                if (value.length <= 250) {
                    setWordCount(value.length);
                    return { ...prevState, [name]: value };
                }
                return prevState;
            }
            return { ...prevState, [name]: value };
        });
    }

    const resetPassword = () => {
        setLoading(true);
        postUrl('/user/contact-us', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Box className=" pos-relative flex-col flex-m" style={{ justifyContent: 'flex-start' }}>
            <div className='setting-subpage-header' style={{ backgroundImage: 'url("/images/setting/contact-us.png")' }}>
            </div>
            <div className='textfield-border' style={{ marginTop: -67, }}>
                <div className=' pos-relative textfield-bkg txt-white' >
                    <div className='p-t-50'>
                        <p className='fs-17 inter-bold'>{t('title.contactUs')}</p>
                        <p className='inter-normal fs-13 cl-label lh-12 p-t-5'>{t('settingDescription.contactUs')}</p>
                    </div>
                    <div>
                        <div className='w-full'>
                            <div className='w-full'>
                                <TextField
                                    variant="outlined"
                                    placeholder={t('user.username')}
                                    fullWidth
                                    type="text"
                                    name="username"
                                    autoComplete="username"
                                    color='primary'
                                    value={state.username}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                    error={inputErrors && inputErrors.username ? true : false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FiUser className="img-style fs-18" style={{ color: '#ffffff' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    placeholder={t('user.email')}
                                    fullWidth
                                    type="email"
                                    name="email"
                                    autoComplete="email"
                                    color='primary'
                                    value={state.email}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                    error={inputErrors && inputErrors.email ? true : false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FiMail className="img-style fs-18" style={{ color: '#ffffff' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    placeholder={t('user.subject')}
                                    fullWidth
                                    type="text"
                                    name="subject"
                                    color='primary'
                                    value={state.subject}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.subject ? inputErrors.subject : ''}
                                    error={inputErrors && inputErrors.subject ? true : false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FiMessageSquare className="img-style fs-18" style={{ color: '#ffffff' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    multiline
                                    minRows={5}
                                    placeholder={t('user.message')}
                                    fullWidth
                                    type="text"
                                    name="message"
                                    color='primary'
                                    value={state.message}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.message ? inputErrors.message : ''}
                                    error={inputErrors && inputErrors.message ? true : false}
                                    inputProps={{
                                        style: {
                                            height: "fit-content",
                                        },
                                    }}
                                />
                                <p className='txt-right p-t-8 fs-10' style={{ color: '#96A4C0' }}>{t('user.maximumChar')} {wordCount} / 250</p>
                            </div>
                        </div>
                        <div className='p-t-20 m-b-130'>
                            <Button variant="contained" color="primary" size="large" onClick={resetPassword} fullWidth><p className='txt-upper' style={{ fontWeight: 600 }}>{t('button.submit')}</p></Button>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    marginTop100: {
        marginTop: 100,
        padding: '0px 10% 0px 10%'
    },
    title: {
        fontSize: 30,
        fontWeight: 700,
        letterSpacing: '-0.3px',
        lineHeight: '130%'
    },
    desc: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '125%'
    }
}));