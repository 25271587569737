import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, loginGame, postUrl } from '@utils/ApiAction';
import { storePlayerId } from '@actions';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import 'swiper/css/bundle';
import { storePaths } from '@utils/Tools';


import {Link, Grid, Hidden } from '@mui/material';

import { FiChevronRight } from "react-icons/fi";
import { FaLink } from "react-icons/fa";

import VerifyEmailDialog from '@components/VerifyEmailDialog';
import GeneologyDialog from '@components/GeneologyDialog';



const Home = memo(() => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    const dispatch = useDispatch();
    const { accessToken, accountSetting } = useSelector(state => state.general);
    const { player_id, email_verified_at } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [walletTooltip, setWalletTooltip] = useState({});
    const [data, setData] = useState({});
    const [geneologyDialog, setGeneologyDialog] = useState(false);
    const { username } = useSelector(state => state.user);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl('dashboard').then(response => {
            if (response.status && isMountedRef.current) {
                setData({ ...data, ...response.data });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

        return () => isMountedRef.current = false;
    }, []);

    const WalletInfo = (props) => {
        let { decimal, balance, locked_balance } = props.info;
        let withdrawableBalance = parseFloat(balance) - parseFloat(locked_balance || 0);
        balance = currencyFormat(parseFloat(balance), decimal);
        locked_balance = currencyFormat(parseFloat(locked_balance || 0), decimal);
        withdrawableBalance = currencyFormat(withdrawableBalance, decimal);

        return (
            <div className='p-t-20 w-full'>
                {/* <p>{`${t('home.currentBalance')}: $ ${balance}`}</p> */}
                <div className='flex-sb-m w-full p-b-5'>
                    <p className='fs-14'>{t('home.withdrawable')}</p>
                    <p>{withdrawableBalance}</p>
                </div>
                <div className='flex-sb-m w-full'>
                    <p className='fs-14'>{t('home.locked')}</p>
                    <p>{locked_balance}</p>
                </div>
            </div>
        )
    }

    const showWalletTooltip = (walletId) => {
        setWalletTooltip({ ...walletTooltip, [walletId]: true })
    }

    const hideWalletTooltip = (walletId) => {
        setWalletTooltip({ ...walletTooltip, [walletId]: false })
    }

    //WalletDesign
    const WALLET_COLOUR = {
        1: 'gradient-main',
        2: 'gradient-tertiary',
        3: 'gradient-theme'
    }
    
    const WALLET_ICON = {
        1: 'logo-awallet.png',
        2: 'logo-usdt.png',
        3: 'logo-rp.png'
    }

    //Dialog
    const shareLink = useRef(null);
    const handleClickOpenGeneologyDialog = () => {
        setGeneologyDialog(true);
    };
    const handleCloseGeneologyDialog = () => {
        setGeneologyDialog(false);
    };
    const copyShareLink = useCallback((e) => {
        shareLink.current.select();
        document.execCommand('copy');
        e.target.focus();

        addAlert('', t('general.copied'), "success");
    }, []);


    

    return (
        <div className='flex-col app-header-margin' style={{ gap: 24 }}>
            {/* wallet balance */}
            <div className='txt-white'>
                <div style={{ width: '73%', paddingTop: 10 }}>
                    <p className='fs-21 p-b-10 txt-title txt-truncate'>{t('home.hi')} {username},</p>
                </div>
                {/* <div className='w-full flex-sb-m'>
                    <div>
                        <p className='fs-14'>{_.find(data.wallets, {id:2})?.name[i18n.resolvedLanguage]} {t('withdrawal.balance')}</p>
                        <p className='fs-21 p-t-5'><b>{currencyFormat(_.find(data.wallets, {id:2})?.balance||0)}</b></p>
                    </div>
                    <div style={{ width: 60 }}>
                        <img src='/images/wallet/coin-usdt-theme.png' alt='coin' />
                    </div>
                </div> */}

                <div className='p-t-20'>
                {data.wallets && _.map(data.wallets, (_wallet, wallet_key) => {
                    
                    return (
                        <div key={wallet_key}>
                            {/* <div className={styles.walletBoxStyle}>
                                <div className="dis-flex flex-t flex-l" style={{ flexDirection: 'column' }} >
                                    <div className="flex-c-m" style={{ gap: 8, flexDirection: 'row' }}>
                                        <Typography sx={{ color: '#101828', fontWeight: 'bold' }} variant="subtitle2" >{_wallet.name[i18n.resolvedLanguage]}</Typography>
                                        
                                    </div>
                                    <Typography variant="body2" sx={{ color: '#667085' }}>{t('home.currentBalance')}</Typography>
                                </div>
                                <div>
                                    <Typography color="primary" variant="h6" sx={{ fontWeight: 'bold', color:'#0CC0DF' }} >$ {currencyFormat(_wallet.balance, 2)}</Typography>
                                </div>
                            </div> */}

                            <div key={_wallet.id} className=' pos-relative p-b-15 pointer w-full'> {/* onClick={() => navigate('/wallet-listing', { state: { from: storePaths(location) } })}  */}
                                <div style={{minHeight: 150 }}>
                                    <div className={`${WALLET_COLOUR[_wallet.id]} txt-white w-full flex-sb-m flex-col p-all-15`} style={{ borderRadius: 10, boxShadow: '-4px -4px 8px 0px rgba(0, 0, 0, 0.25) inset, 4px 4px 8px 0px rgba(255, 255, 255, 0.63) inset'}}>
                                        <div className='w-full flex-sb-m'>
                                            <div className='p-t-10 p-b-10 w-full' style={{ textShadow: '1px 1px 1px #0000003b' }}>                                               
                                                <p className='p-b-5'>{_wallet.name[i18n.resolvedLanguage]}</p>
                                                <p className='fs-20'><b>{currencyFormat(_wallet.balance, 2)}</b></p>
                                            </div>
                                            <img src={`/images/wallet/${WALLET_ICON[_wallet.id]}`} alt='wallet' style={{ width: 50, opacity:0.5, filter: ' drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15))' }} />
                                        </div>
                                        <WalletInfo info={_wallet}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                </div>
            </div>

            {/* genealogy */}
            <div className='glass-box-hightlight' >
                <div style={{ background: '#16114e', borderRadius: 10 }}>
                    <div className='glass-box '>
                        <div className='p-b-8 p-t-10 flex-sb-m w-full' style={{ marginTop: -10 }}>
                            <div className='flex-m'>
                                <Link component={RouterLink} to={`/community/sponsor`} state={{ from: storePaths(location) }} underline='none'>
                                    <p className='txt-upper fs-14 p-b-2 flex-m txt-white'><b>{t('genealogy.myDownline')}</b></p>
                                </Link>
                                <FiChevronRight className='m-l-10 clwhite' />
                            </div>
                            {/* <p className='fs-12 txt-right p-r-10'>{t('button.copyLink')}</p> */}
                            <div className='flex-m'>
                                <p className='fs-12 lh-11 p-r-12 clwhite txt-right'>{t('button.clickFor')}<br/>{t('button.referralLink')}</p>
                                <div className='pointer flex-c-m theme-bg btn-small-square' style={{width:30, height:30}}  onClick={handleClickOpenGeneologyDialog}>
                                    {/* <img className='w-full' src={`/images/general/qr.png`} alt="genealogy" /> */}
                                    {/* <img className='w-full' src={`/images/general/qr2.png`} alt="genealogy" /> */}
                                    <FaLink style={{color:'#222'}} />
                                </div>
                            </div>
                        </div>
                        <div className='txt-white flex-sb-m w-full'>
                            <div className='flex-c-m' style={{ width: 130 }}>
                                <img className='w-full' src={`/images/general/genealogy.png`} alt="genealogy" />
                            </div>
                            <div className='w-full p-l-15'>
                                <div className='w-full flex-sb-m'>
                                    <div className='txt-center' style={{ width: '50%' }}>
                                        <p className='fs-17'><b>{data?.downlines?.direct_downlines || 0}</b></p>
                                        <p className='fs-11 txt-upper p-t-5'>{t('genealogy.totalDirect')}</p>
                                    </div>
                                    <div className='txt-center' style={{ width: '50%' }}>
                                        <p className='fs-17'><b>{data?.downlines?.all_downlines || 0}</b></p>
                                        <p className='fs-11 txt-upper p-t-5'>{t('genealogy.total')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='p-t-20' style={{display:'none'}}>
                            <textarea ref={shareLink} value={`${process.env.REACT_APP_LOCAL_URL}/register?referral=${username}`} readOnly style={{ border: 'none', background: 'transparent', resize: 'none', outline: 'none', fontSize: 12, height:17 }} className='w-full clwhite lh-10' />
                        </div>
                        {document.queryCommandSupported('copy') &&
                            <div className='p-t-15'>
                            <p className='cl-label fs-12 p-b-5'>{`${process.env.REACT_APP_LOCAL_URL}/register?referral=${username}`}</p>
                            <div className='pointer button-large' variant="contained" onClick={copyShareLink}>{t('button.copyLink')}</div>
                            </div>
                        } */}
                    </div>
                </div>
                
            </div>
            <GeneologyDialog dialog={geneologyDialog}  handleCloseDialog={handleCloseGeneologyDialog} />

        
            {/* x3 Earning */}
            <div>
                <div className='glass-box-hightlight' >
                    <div style={{ background: '#16114e', borderRadius: 10 }}>
                        <div className='glass-box txt-white'>
                            <div className='flex-sb-m'>
                                <div className='p-b-10 p-t-15'>
                                  
                                        <p className='txt-upper fs-12'>{t('home.myPersonalStaking')}</p>
                                        <div className='flex-m'>
                                        <p className='fs-17 p-r-10 p-t-5'><b>{data?.stake?.sum}</b></p>
                                        <div style={{ width: 20, transform: 'rotate(35deg)', marginBottom: -10 }}>
                                        <img src='/images/general/x3.png' alt="x3" />
                                    </div>
                                    </div>
                                    
                                </div>
                                <Link component={RouterLink} to={`/stake`} state={{ from: storePaths(location) }} underline='none'>
                                <FiChevronRight className='fs-20 clwhite' />
                                </Link>
                            </div>
                            <div className='pos-relative'>
                                <div className='progressbar-bkg'></div>
                                <div className='pos-absolute progressbar' style={{ top: 0, width: `${((parseFloat(data?.stake?.profit_limit)-parseFloat(data?.stake?.profit_limit_balance))/parseFloat(data?.stake?.profit_limit)*100)}%`, background: 'linear-gradient(107deg, #57C0F3 5.06%, #5BD8E9 53.22%, #57C4F1 95.07%',  }}></div>
                            </div>
         
                            <div className='flex-sb-m fs-12 p-t-10 p-b-10'>
                                <p className='txt-upper'>{t('home.bonusEarn')}</p>
                                <p>{currencyFormat(parseFloat(data?.stake?.profit_limit)-parseFloat(data?.stake?.profit_limit_balance)||0)} / {currencyFormat(data?.stake?.profit_limit)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='glass-shadow m-t-20'></div>
            </div>
            {/* <div className='txt-white' style={{fontWeight:500}}>
                <div className='flex-sb-m'>
                    <p className='title-section'>{t('wallet.bonusSummary')}</p>
                    <Link component={RouterLink} to={`/wallets/commission`} state={{ from: storePaths(location) }} underline='none'><FiChevronRight /></Link>
                </div>
                <div className='flex-sb-m w-full p-t-5'>
                    <p className='fs-14'>{t('wallet.total')}</p>
                    <p className='fs-16 txt-right'><b>{currencyFormat(data?.comm?.all_comm?.total || 0)}</b></p>
                </div>
                <div className='flex-sb-m w-full p-t-5'>
                    <p className='fs-14'>{t('wallet.lastWeek')}</p>
                    <p className='fs-16 txt-right flex-m'>
                    <span className='p-r-10 flex-m' style={{color:'#5CE1E6'}}><HiMiniArrowUpCircle className='m-r-5'/> 65%</span>
                    <b>{currencyFormat(data?.comm?.last_week?.total || 0)}</b></p>
                </div>
            </div> */}

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className='bkg-roi txt-white' style={{ paddingTop: 0 }}>
                        <div className='cut-edge-bottom flex-c-m' style={{ height: 30, width: 250, maxWidth: '90%', margin: '0 auto', }}>
                            <p className='txt-center txt-upper fs-14' style={{ color: '#197794' }}><b>ROI {t('wallet.summary')}</b></p>
                        </div>
                        <div className='flex-sb-m w-full p-t-15 p-b-8'>
                            <div>
                                <p className='txt-upper fs-12'>{t('wallet.totalEarn')}</p>
                                <p className='fs-17 p-t-5'>{currencyFormat(data?.comm?.all_comm[1] || 0)}</p>
                            </div>
                            <div style={{ width: 50 }}>
                                <img src='/images/bonus/roi.png' alt='bonus' />
                            </div>
                        </div>
                        {/* <div className='pos-relative'>
                        <div className='progressbar-bkg'></div>
                        <div className='pos-absolute progressbar' style={{ top: 0, width: '50%', background:'#fff'}}></div>
                    </div> */}

                        <div className='p-t-5'>
                            <div className='flex-sb-m w-full p-t-5'>
                                <p className='fs-14'>{t('wallet.total')}</p>
                                <p className='fs-16 txt-right'><b>{currencyFormat(data?.comm?.all_comm[1] || 0)}</b></p>
                            </div>
                            <div className='flex-sb-m w-full p-t-5'>
                                <p className='fs-14'>{t('wallet.lastWeek')}</p>
                                <p className='fs-16 txt-right flex-m'>{/* <span className='p-r-10 flex-m' style={{ color: '#fff' }}><HiMiniArrowUpCircle className='m-r-5' /> 65%</span> */}<b>{currencyFormat(data?.comm?.last_week[1] || 0)}</b></p>
                            </div>
                        </div>

                    </div>
                    <div className='shadow-roi'></div>
                </Grid>

                <Grid item xs={12}>
                    <div className='bkg-matching txt-white' style={{ paddingTop: 0 }}>
                        <div className='cut-edge-bottom flex-c-m' style={{ height: 30, width: 250, maxWidth: '90%', margin: '0 auto', }}>
                            <p className='txt-center txt-upper fs-14' style={{ color: '#8F5CAE' }}><b>Matching {t('wallet.summary')}</b></p>
                        </div>
                        <div className='flex-sb-m w-full p-t-15 p-b-8'>
                            <div>
                                <p className='txt-upper fs-12'>{t('wallet.totalEarn')}</p>
                                <p className='fs-17 p-t-5'>{currencyFormat(data?.comm?.all_comm[2] || 0)}</p>
                            </div>
                            <div style={{ width: 50 }}>
                                <img src='/images/bonus/matching.png' alt='bonus' />
                            </div>
                        </div>
                        {/* <div className='pos-relative'>
                        <div className='progressbar-bkg'></div>
                        <div className='pos-absolute progressbar' style={{ top: 0, width: '50%', background:'#fff'}}></div>
                    </div> */}

                        <div className='p-t-5'>
                            <div className='flex-sb-m w-full p-t-5'>
                                <p className='fs-14'>{t('wallet.total')}</p>
                                <p className='fs-16 txt-right'><b>{currencyFormat(data?.comm?.all_comm[2] || 0)}</b></p>
                            </div>
                            <div className='flex-sb-m w-full p-t-5'>
                                <p className='fs-14'>{t('wallet.lastWeek')}</p>
                                <p className='fs-16 txt-right flex-m'>{/* <span className='p-r-10 flex-m' style={{ color: '#fff' }}><HiMiniArrowUpCircle className='m-r-5' /> 65%</span> */}<b>{currencyFormat(data?.comm?.last_week[2] || 0)}</b></p>
                            </div>
                        </div>

                    </div>
                    <div className='shadow-matching'></div>
                </Grid>
            </Grid>

            {/* wallet */}
            {/* <div >
            <Swiper
                slidesPerView={_.size(data.wallets) > 1 ? 2 : 1}
                spaceBetween={16}
                freeMode={true}
                modules={[FreeMode]}
                style={{ width: "100%" }}
            >
                {data.wallets && _.map(data.wallets, _wallet => {
                    return (
                        <SwiperSlide key={_wallet.id}>
                            <div className={styles.walletBoxStyle}>
                                <div className="dis-flex flex-t flex-l" style={{ flexDirection: 'column' }} >
                                    <div className="flex-c-m" style={{ gap: 8, flexDirection: 'row' }}>
                                        <Typography sx={{ color: '#101828', fontWeight: 'bold' }} variant="subtitle2" >{_wallet.name[i18n.resolvedLanguage]}</Typography>
                                        <ClickAwayListener onClickAway={() => hideWalletTooltip(_wallet.id)}>
                                            <Tooltip
                                                title={<WalletInfo info={_wallet} />}
                                                open={walletTooltip[_wallet.id] || false}
                                                onClose={() => hideWalletTooltip(_wallet.id)}
                                            >
                                                <div onClick={() => showWalletTooltip(_wallet.id)}>
                                                    <FiEye style={{ width: 14, height: 14 }} className={styles.iconHoverStyle} />
                                                </div>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    </div>
                                    <Typography variant="body2" sx={{ color: '#667085' }}>{t('home.currentBalance')}</Typography>
                                </div>
                                <div>
                                    <Typography color="primary" variant="h6" sx={{ fontWeight: 'bold', color:'#0CC0DF' }} >$ {currencyFormat(_wallet.balance, 2)}</Typography>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            </div> */}
        </div>
    )
});

const useStyles = makeStyles((theme) => ({
    iconHoverStyle: {
        color: '#667085',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    iconStyle: {
        color: '#FFFFFF',
    },
    walletBoxStyle: {
        flexDirection: 'column',
        borderRadius: 18,
        backdropFilter: 'blur(40px)',
        backgroundColor: '#F2F4F7',
        // width: '50%',
        gap: 16,
        padding: '21px 57px 21px 15px',
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            padding: 15,
        },
        [theme.breakpoints.up('sm')]: {
            padding: 15,
        },
    },
}));

export default Home;