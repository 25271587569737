import { storeProfile } from '@actions';
// import { isUndefined } from 'lodash';
import _ from 'lodash';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, putUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateImported } from '@actions';
import COUNTRIES from '@utils/CountryList';

//MUI
import { Alert, AlertTitle, Button, Grid, TextField, Typography, Badge, Avatar, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, ListItem, ListItemButton, List, ListItemText, ListItemIcon, InputAdornment, Slide, Select, MenuItem, FormHelperText } from '@mui/material/';
import { FiImage, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { GoAlert, GoAlertFill } from "react-icons/go";
import { FiChevronRight } from "react-icons/fi";

import DeleteConfirmationModal from '@layouts/DeleteConfirmationModal';
import { ChevronLeft } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function BasicInfoProfile() {
    const theme = useTheme();
    const styles = useStyles();
    const { t } = useTranslation();
    const { username, name, email, mobile, country, email_verified_at, profile_image, sub_email } = useSelector(state => state.user);
    const { accountSetting, importedUser } = useSelector(state => state.general);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: name,
        email: email,
        mobile: mobile,
        country: country,
        username: username,
    });
    const [option, setOption] = useState({
        showImageOption: false,
        previewImage: false,
        deleteConfirm: false,
    });
    const [imgState, setImgState] = useState({
        image: null,
        previewUrl: null,
    });
    const hiddenFileInput = useRef();
    // const [verifyButton, setVerifyButton] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        let apiData = {
            name: state.name,
            email: state.email,
            mobile: state.mobile,
            country: state.country,
        }

        putUrl(`/user`, apiData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                dispatch(updateImported());
                addAlert('', t('success.editSuccess'), 'success', '');
                setState({
                    ...state,
                    existing_image: response.data.profile_picture,
                });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // const verifyEmail = () => {
    //     setLoading(true);
    //     setInputErrors();
    //     getUrl(`/user/send-email-verification-url/verify`).then(response => {
    //         setLoading(false);
    //         if (response.status) {
    //             setVerifyButton(true);
    //             addAlert('', response.message, 'success', '');
    //         } else {
    //             setInputErrors(response.errors);
    //             addAlert('', t('error.editError'), 'error', '');
    //         }
    //     }).catch(error => {
    //         setLoading(false);
    //         addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //     });
    // }

    const openMenu = () => {
        setOption({ ...option, showImageOption: true });
    }

    const closeMenu = () => {
        setOption({ ...option, showImageOption: false });
    }

    const handleImageChange = ({ target }) => {
        setImgState({ ...state, image: target.files[0] });
        if (!_.isUndefined(target.files[0])) {
            setOption({ ...option, showImageOption: false, previewImage: true });
        }
        target.value = null;
    }

    useEffect(() => {
        if (state.image) {
            setImgState({ ...state, previewUrl: URL.createObjectURL(state.image) })
        } else {
            setImgState({ ...state, previewUrl: null });
        }
    }, [state.image]);

    useEffect(() => {
        setState({ ...state, username: username, name: name, email: email, mobile: mobile, country: country });
        // eslint-disable-next-line
    }, [username, name, email, mobile, country])

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const closePreview = () => {
        setOption({ ...option, previewImage: false });
    }

    const saveProfileImage = () => {
        setInputErrors({});
        closePreview();
        setLoading(true);
        const formData = new FormData();
        formData.append("_method", "put");
        formData.append("profile_image", state.image);

        postUrl('/user/upload-profile-image', formData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                addAlert('', t('success.imageUploadSuccess'), 'success', '');
                setImgState({
                    ...imgState,
                    image: null,
                    previewUrl: null,
                });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.imageUploadFailed'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const removeProfileImage = () => {
        setLoading(true);
        if (profile_image) {
            putUrl(`/user/${profile_image.id}/delete-profile-image`).then(response => {
                setLoading(false);
                if (response.status) {
                    dispatch(storeProfile(response.data));
                    addAlert('', t('success.imageUploadSuccess'), 'success', '');
                    setImgState({
                        ...imgState,
                        image: null,
                        previewUrl: null,
                    });
                    setOption({ ...option, deleteConfirm: false });
                    addAlert('', t('success.imageRemoveSuccess'), 'success', '');
                } else {
                    addAlert('', t('error.imageRemoveFailed'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            addAlert('', t('error.contactSupport'), 'error', '');
        }
    }

    const resendEmailUpdateVerification = () => {
        setLoading(true);
        getUrl(`/user/send-email-verification-url/update`).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    //Dialog

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

       

    return (
        <div className=" pos-relative w-full flex-col flex-m" style={{ justifyContent: 'flex-start' }}>
            <div className='setting-subpage-header' style={{ backgroundImage: 'url("/images/setting/profile.png")' }}>
            </div>
            <div className='textfield-border' style={{ marginTop: -67, }}>
                <div className=' pos-relative textfield-bkg txt-white' >
                    <div className='p-t-50'>
                        <p className='fs-17 inter-bold'>{t('title.profile')}</p>
                        <p className='inter-normal fs-13 cl-label lh-12 p-t-5'>{t('settingDescription.profile')}</p>
                    </div>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                        {
                            state?.email == null &&
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    <AlertTitle style={{ color: 'red', fontWeight: 'bold' }}>{t('general.warning')}</AlertTitle>
                                    <Typography style={{ color: 'black', textAlign: 'left' }} variant='body2' >{t('user.userEmailWarning')}</Typography>
                                </Alert>
                            </Grid>
                        }
                        {
                            importedUser &&
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    <AlertTitle style={{ color: 'red', fontWeight: 'bold' }}>{t('general.warning')}</AlertTitle>
                                    <Typography style={{ color: 'black', textAlign: 'left' }} variant='body2' >{t('user.changeEmailWarning')}</Typography>
                                </Alert>
                            </Grid>
                        }
                        <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" style={{ gap: 6, display: 'none' }} >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            // badgeContent={
                            //     <Avatar alt="Edit" className={styles.smallAvatarStyle} onClick={openMenu} ><HiOutlinePencil style={{ color: 'white', height: 15, width: 15, cursor: 'pointer' }} /></Avatar>
                            // }
                            >
                                {
                                    profile_image ?
                                        <Avatar className={styles.avatarStyle} src={profile_image?.file_name} />
                                        :
                                        (
                                            name ?
                                                <Avatar className={styles.avatarStyle}>
                                                    <Typography variant='h3'>{name.charAt(0).toUpperCase()}</Typography>
                                                </Avatar>
                                                :
                                                <Avatar className={styles.avatarStyle} />
                                        )
                                }
                            </Badge>
                            {
                                inputErrors?.profile_image &&
                                <Typography style={{ color: 'red', paddingTop: 2 }}>{inputErrors?.profile_image}</Typography>
                            }
                            <Typography variant='h6' style={{ textAlign: 'center', wordBreak: 'break-all' }} >{name || ''}</Typography>
                        </Grid>
                    </Grid>

                    <div className='p-t-20'>
                        <TextField
                            variant="standard"
                            fullWidth
                            name='username'
                            // label={t('user.username')}
                            placeholder={t('user.username')}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiUser className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                            }}
                            disabled
                            value={state.username || ''}
                        />
                         <TextField
                                variant="standard"
                                fullWidth
                                name='name'
                                // label={t('user.name')}
                                placeholder={t('user.name')}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FiUser className="img-style fs-18" style={{ color: '#ffffff' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                value={state.name || ''}
                            />
                        <TextField
                                variant="standard"
                                fullWidth
                                name='email'
                                // label={t('user.email')}
                                placeholder={t('user.email')}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FiMail className="img-style fs-18" style={{ color: '#ffffff' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                value={state.email || ''}
                            // disabled={!importedUser}
                            />
                            {sub_email &&
                            <div className='p-t-8 flex-sb-m'>
                                <div className='flex-m' style={{ colo: '#f39090' }}>
                                    <GoAlertFill style={{color:'#CB6CE6'}} />
                                    <p className='p-l-10 fs-12  txt-lower'><span className='cl-theme '>{sub_email}</span> {t('user.subEmail')}</p>
                                </div>
                                <div className='fs-12 flex-m pointer translateX' style={{color:'#5de1e5'}} onClick={handleClickOpen}>
                                    <p className='p-r-8'>Details</p>
                                    <FiChevronRight/>
                                </div>
                            </div>
                            }

                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <Alert severity="info">
                                {/* <AlertTitle style={{ color: 'black', fontWeight: 'bold' }}>{t('general.info')}</AlertTitle> */}
                                <p className='inter-bold fs-13 txt-upper p-b-10'> {t('user.subEmail')}</p>
                                <p className='fs-13'>
                                    <span className='inter-bold'>{sub_email}</span>
                                </p>
                                <p className='inter-normal fs-13 lh-12 p-t-10' >{t('user.verifyEmail')}</p>
                                <Button variant="contained" size="small" onClick={resendEmailUpdateVerification} style={{ marginTop: 15 }}>{t('user.resendVerificationLink')}</Button>
                            </Alert>
                        </Dialog>

                        {/* {sub_email ?
                            <div className='p-t-20'>
                                <Alert severity="info">
                                    <AlertTitle style={{ color: 'black', fontWeight: 'bold' }}>{t('general.info')}</AlertTitle>
                                    <p className='inter-bold fs-13 txt-upper p-b-10'> {t('user.subEmail')}</p>
                                    <p className='fs-13'>
                                        <span className='inter-bold'>{sub_email}</span>
                                    </p>
                                    <p className='inter-normal fs-13 lh-12 p-t-10' >{t('user.verifyEmail')}</p>
                                    <Button variant="contained" size="small" onClick={resendEmailUpdateVerification} style={{ marginTop: 15 }}>{t('user.resendVerificationLink')}</Button>
                                </Alert>
                            </div>
                            : null} */}
                        {/* {
                    (accountSetting?.email === true && email_verified_at === null) ?
                        <Grid item xs={12}>
                            <Button variant="contained" disabled={verifyButton} onClick={verifyEmail} >{t('button.verify')}</Button>
                        </Grid>
                        : null
                } */}
                        <TextField
                            variant="standard"
                            fullWidth
                            name='mobile'
                            // label={t('user.mobile')}
                            placeholder={t('user.mobile')}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiPhone className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                            }}
                            value={state.mobile || ''}
                        />
                        <Select
                            style={{width: '100%' }}
                            value={state?.country || 0}
                            name="country"
                            onChange={handleChange}
                            displayEmpty
                            error={inputErrors && inputErrors.country ? true : false}
                        >
                            <MenuItem key={0} value={0} disabled>--- {`${t('general.selectCountry')}`} --- </MenuItem>
                            {
                                _.map(COUNTRIES, (data, key) => {
                                    return <MenuItem key={key} value={data?.value}>{data?.label}</MenuItem>
                                })
                            }
                        </Select>
                        {
                            inputErrors && inputErrors?.country &&
                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors?.country}</FormHelperText>
                        }
                        <div className='p-t-40' >
                            <Button variant="contained" onClick={submitData} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.save')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            
            

            {/* upload image */}
            <input accept="image/*" className={styles.input} type="file" onChange={handleImageChange} ref={hiddenFileInput} />
            <Dialog open={option?.showImageOption} onClose={closeMenu}>
                <DialogTitle style={{ paddingTop: 40 }}>{t('dialog.imageOptionTitle')}</DialogTitle>
                <DialogContent style={{ padding: '0 24px 10px', width: '100%' }}>
                    <List className='flex-c-m' style={{ backgroundColor: theme.palette.dialogContentBg, borderRadius: 12, border: '1px dashed #ffffff3b' }}>
                        <ListItem>
                            <ListItemButton onClick={handleClick} className='flex-col' style={{ padding: '20px 40px' }}>
                                <ListItemIcon style={{ minWidth: 'unset', paddingBottom: 10 }}>
                                    <FiImage className="img-style" />
                                </ListItemIcon>
                                <ListItemText primary={t('dialog.chooseNewImage')} />
                            </ListItemButton>
                        </ListItem>
                        {
                            profile_image &&
                            <ListItem>
                                <ListItemButton onClick={() => setOption({ ...option, showImageOption: false, deleteConfirm: true })}>
                                    <ListItemIcon>
                                        <RiDeleteBin7Line className="img-style" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('dialog.deleteProfileImage')} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                </DialogContent>
                <DialogActions style={{ width: 'calc(100% - 39px)', paddingBottom: 40 }}>
                    <Button variant="contained" onClick={closeMenu} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.close')}</Button>
                </DialogActions>
            </Dialog>
            {/* preview image */}
            <Dialog open={option?.previewImage} onClose={closePreview} >
                <DialogTitle style={{ paddingTop: 40 }}>{t('dialog.imageOptionTitle')}</DialogTitle>
                <DialogContent style={{ padding: '0 24px 10px', width: '100%' }}>
                    <List style={{ backgroundColor: theme.palette.dialogContentBg, borderRadius: 12, padding: 10, border: '1px dashed #ffffff3b' }}>
                        <ListItem style={{ justifyContent: 'center', padding: '20px 40px' }}>
                            <Avatar className={styles.avatarStyle} src={state.previewUrl} />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions style={{ width: 'calc(100% - 39px)', paddingBottom: 40 }}>
                    <Button variant="outlined" onClick={closePreview} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.cancel')}</Button>
                    <Button variant="contained" onClick={saveProfileImage} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.save')}</Button>

                </DialogActions>
            </Dialog>
            <DeleteConfirmationModal open={option.deleteConfirm} closeDeleteDialog={() => setOption({ ...option, deleteConfirm: false })} submitData={removeProfileImage} />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    avatarStyle: {
        height: 96,
        width: 96,
        flexShrink: 0,
    },
    smallAvatarStyle: {
        width: 34,
        height: 34,
        border: `1px solid ${theme.palette.white}`,
        backgroundColor: '#EF4B27',
        cursor: 'pointer',
        flexShrink: 0,
    },
    input: {
        display: "none",
    },
}));