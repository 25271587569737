import { Button, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { currencyFormat } from '@utils/Tools';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { BiDollarCircle } from "react-icons/bi";
import { MdAddCard, MdCurrencyExchange, MdOutlineVideogameAsset, MdSend } from "react-icons/md";
import { storePaths } from '@utils/Tools';

import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { FiEye, FiEyeOff, FiChevronRight } from 'react-icons/fi';
import 'swiper/css/bundle';

const BONUS_COLOUR = {
    2: 'bkg-matching ',
    1: 'bkg-roi'
}

const BONUS_SHADOW = {
    2: 'shadow-matching ',
    1: 'shadow-roi'
}

const BONUS_ICON = {
    2: 'matching.png',
    1: 'roi.png'
}

export default function CommissionDashboard(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { state, setState } = props
    const [slideView, setSlideView] = useState(0);
    return (
        <div style={{ marginTop: -70 }}>

            <div className='p-t-70'>
                <div className='txt-white txt-center p-b-20'>
                    <p className='txt-upper fs-12 p-b-5'>{t('home.totalBonusEarn')}</p>
                    <p className='fs-23'><b>{currencyFormat(parseFloat(state?.totalComm?.all_time||0))}</b></p>
                </div>
                <div className='glass-box-hightlight w-full' style={{ boxShadow: '0px 5px 4px rgba(0, 0, 0, 0.25)' }}>
                    <div style={{ background: '#16114e', borderRadius: 10 }}>
                        <div className='glass-box txt-white w-full flex-sa-m'>
                            <div className='txt-center'>
                                <p>{currencyFormat(parseFloat(state?.totalComm?.this_week||0))}</p>
                                <p className='txt-upper fs-10 p-t-3'>{t('wallet.thisWeek')}</p>
                            </div>
                            <div style={{ borderRadius: 20, opacity: 0.3, width: 4, height: 25, background: '#D9D9D9', boxShadow: 'rgb(0, 0, 0) 1.5px 0 1px 0px inset' }}></div>
                            <div className='txt-center'>
                                <p>{currencyFormat(parseFloat(state?.totalComm?.last_week||0))}</p>
                                <p className='txt-upper fs-10 p-t-3'>{t('wallet.lastWeek')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container spacing={3} className='p-t-50'>

                {
                    _.size(state.commissions) > 0 && _.map(state.commissions, (detail, index) => {
                        return (
                            <Grid item xs={6} className='p-all-10'>
                                <div key={index} className=' pos-relative flex-c-m  pointer' onClick={() => navigate('/commission-listing', { state: { from: storePaths(location) } })}>
                                    <div className={`${BONUS_COLOUR[detail.id]}`} style={{ aspectRatio: 1 / 1, width: '90%', maxWidth: '100%', transform: 'rotate(-11.095deg)' }}></div>
                                    <div className='pos-absolute w-full' style={{ left: 10 }}>
                                        <div className='glass-box txt-white w-full w-full flex-sb-m flex-col' style={{ boxShadow: 'rgba(255, 255, 255, 0.14) 0px 4px 4px 0px inset, inset -1px -2px 3px 0 #ffffff4d, inset 3px 0px 3px 0 #ffffff4d', padding: 13, aspectRatio: 1 / 1, width: '90%', filter: 'drop-shadow(1px 1px 1px #0003)' }}>
                                            <div className='w-full flex-r-m' style={{ opacity: 0.5 }}>
                                                <img src={`/images/bonus/${BONUS_ICON[detail.id]}`} alt='wallet' style={{ width: 45, filter: ' drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15))' }} />
                                            </div>
                                            <div className='txt-white w-full'>
                                                <p className='txt-upper fs-13'>{detail.name[i18n.resolvedLanguage]}
                                                    {
                                                        state.walletDecimal === 2 ?
                                                            <FiEye className='fs-12 m-l-10 pointer' onClick={() => setState({ ...state, walletDecimal: 4 })} />
                                                            :
                                                            <FiEye className='fs-12 m-l-10 pointer' onClick={() => setState({ ...state, walletDecimal: 2 })} />
                                                    }</p>
                                                <div className='w-full flex-sb-m'>
                                                    <p>{currencyFormat(detail.total_amount * 1, state.walletDecimal)}</p>
                                                    <FiChevronRight />
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                                <div className='w-full flex-r-m'>
                                    <div className={`${BONUS_SHADOW[detail.id]} m-t-20 max-w-full`} style={{ opacity: 0.3, filter: 'blur(5px)', width: 90, height: 7 }}> </div>
                                </div>
                            </Grid>
                        )
                    })
                }


            </Grid>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    funcButton: {
        background: 'linear-gradient(90deg, #55D3FF,#684EFF)',
        padding: 2,
        marginX: 10,
        width: 64,
        height: 64,
        flexDirection: 'column',
        zIndex: 1,
        borderRadius: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        color: '#ffffff'
    }
}));