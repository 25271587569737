import React, { useEffect, useRef, useState } from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { Avatar, Card, CardContent, Grid, IconButton, Tooltip, Menu, MenuItem, TextField, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import theme from '../../Theme';
import { currencyFormat } from '@utils/Tools';
import Transition from 'react-transition-group/Transition';

//ICON
import { FiChevronDown, FiChevronRight, FiChevronUp } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
// import { PiArrowArcLeftBold } from "react-icons/pi";

export default function GenealogySponsor(props) {
    const { t } = useTranslation();
    // const { id } = useSelector(state => state.user);
    const styles = useStyles();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading , loading } = useNotificationLoading();
    // const navigate = useNavigate();
    const { username } = useSelector(state => state.user);
    
    const [openSearch, setOpenSearch] = useState(false);
    const [tree, setTree] = useState([]);
    const [expandMore, setExpandMore] = useState({});
    const [state, setState] = useState({ totalDirectDownlines: 0, totalDownlines: 0, username: '' });
    const [searchUpline, setSearchUpline] = useState({});
    const [searchCurrentUser, setSearchCurrentUser] = useState('');
    // const [searchDownline, setSearchDownline] = useState({});
    useEffect(() => {
        isMountedRef.current = true;
        setTree(props.state.data);
        setState({ ...state, username: props.state.data.username });
        let expandStateArr = {};
        expandStateArr[props.state.data.id] = { id: props.state.data.id, expand: true };
        _.map(props.state.data.children, node => (
            expandStateArr[node.id] = { id: node.id, expand: false }
        ))
        setExpandMore(expandStateArr);
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [props.state])

    const searchUsername = (targetUser) => {
        setLoading(true);
        getUrl(`/sponsor-genealogy`, { username: targetUser ? targetUser : props.state.data.username }).then(response => {
            if (isMountedRef.current) {
                let { status, message, error, data } = response;
                if (status) {
                    setState({ ...state, username: data.tree_data.username || username });
                    setTree(data.tree_data);
                    setSearchCurrentUser(targetUser ? targetUser : props.state.data.username);
                    let expandStateArr = {};
                    if (props.state.data.username) {
                        expandStateArr[data.tree_data.id] = { id: data.tree_data.id, expand: true };
                        _.map(data.tree_data.children, node => (
                            expandStateArr[node.id] = { id: node.id, expand: false }
                        ))
                    } else {
                        _.map(data.tree_data.children, node => (
                            expandStateArr[node.id] = { id: node.id, expand: false }
                        ))
                    }
                    setExpandMore(expandStateArr);
                    // setSearchDownline(data.downline);
                    setSearchUpline(data.upline);
                    setOpenSearch(false);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        addAlert(message);
                    }
                }
            }
            setLoading(false);
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }
        });
    }

    const handleExpand = (id) => {
        setExpandMore({ ...expandMore, [id]: { ...expandMore.id, expand: !expandMore[id].expand } });
    }

    const renderTree = (nodes) => {
        if (_.size(nodes) > 0)
        {
            return (
                <TreeItem 
                    key={nodes.id} 
                    nodeId={`${nodes.id}`}
                    className={`${styles.downlineBoxDesign} ${_.size(nodes.children) > 0 ? '' : styles.disabledCursor}`}
                    onClick={() => handleExpand(nodes.id)}
                    disabled={_.size(nodes.children) > 0 ? false : true}
                    id="sponsor"
                    label={
                        <div className='flex-sb-m'>
                            <div className='flex-m w-full'>
                                <div className='p-r-15'>
                                    <Avatar sx={{ width: 50, height: 50 }}>{String(nodes?.username).charAt(0).toUpperCase()}</Avatar>
                                </div>
                                <div className='w-full'>
                                    <div className='flex-sb-m w-full'>
                                        <div>
                                            <div className='flex-m'>
                                                <div>
                                                    <p className='flex-sb-m txt_truncate1 txt-upper txt-white p-r-10 fs-17'><b>{nodes.username}</b></p>
                                                    <p className='cl-label fs-12 p-b-5 p-t-2' style={{ fontWeight: 500 }}>{moment(nodes.user_created_at).format('DD/MM/YYYY')}</p>
                                                </div>
                                                {
                                                    (nodes.rank_code || nodes.crank_code) &&
                                                    <div className='lh-0'>
                                                        <img src={`/images/rank/${nodes.rank_code > nodes.crank_code ? nodes.rank_code : nodes.crank_code}.png`} alt='rank' className='p-l-5' style={{ height: 30, width: 'auto' }} />
                                                    </div>
                                                }
                                            </div>
                                            
                                        </div>
                                        <div className='flex-r-m' style={{ width: 20 }}>
                                            {_.size(nodes.children) > 0 ?
                                                <div>
                                                    {
                                                        expandMore?.[nodes.id]?.expand ?
                                                            <div className='btn-small-square flex-c-m'>
                                                                <FiChevronDown className='fs-16' style={{color:'#16114e'}} />
                                                            </div>
                                                            : <div className='btn-small-square flex-c-m'>
                                                                <FiChevronUp className='fs-16' style={{color:'#16114e'}} />
                                                            </div>
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        
                                    </div>
                                    <div className='p-t-5 flex-sb-m w-full'>
                                        <div>
                                            <p className='fs-11 txt-upper p-t-5'>{t('stake.individual')} {currencyFormat(nodes.personal_sales, 2)}</p>
                                            <p className='fs-11 txt-upper p-t-5'>{t('stake.direct')} {currencyFormat(nodes.direct_sales, 2)}</p>
                                            <p className='fs-11 txt-upper p-t-5'>{t('stake.group')} {currencyFormat(nodes.group_sales, 2)}</p>
                                        </div>
                                        <div className='txt-right p-r-10'>
                                            <p className='fs-16'>{nodes.total_direct_downline}</p>
                                            <p className='fs-12 cl-label'>{t('genealogy.totalDirect')}</p>
                                        </div>
                                    </div>
                                    {/* <Typography variant='body1' className='txt-truncate ' style={{ color: '#667085' }}>Total Package: 0</Typography> */}
                                </div>
                            </div>
                            
                        </div>
                    }
                >
                    {
                        Array.isArray(nodes?.children) 
                            ? _.map(nodes?.children , (node) => renderSubTree(node)) 
                            : null
                    }
                </TreeItem>
            )
        }
        else
        {
            return null;
        }
        
    };
    
    const renderSubTree = (nodes) => {
        return (
            <div key={nodes.id}>
                <div className="divider-white m-tb-20"></div>
                <div className={`${nodes.total_downline > 0 ? "button-active" : "button-disable"} w-full`}  onClick={() => searchUsername(nodes.username)} >
                    <div className='flex-sb-m w-full' >
                        <div className='flex-sb-m w-full'>
                            
                            <div className='flex-m'>
                                <div>
                                    <p className="cl-label fs-12">{t('general.member')}</p>
                                    <p className="txt-truncate flex-m clwhite fs-16"><b>{nodes.username}</b></p>
                                </div>
                                {
                                    (nodes.rank_code || nodes.crank_code) &&
                                    <div>
                                        <img src={`/images/rank/${nodes.rank_code > nodes.crank_code ? nodes.rank_code : nodes.crank_code}.png`} alt='rank' className='p-l-5' style={{ height: 25, width: 'auto' }} />
                                    </div>
                                }
                            </div>
                            
                            {/* <Typography variant='body1' className='txt-truncate ' style={{ color: '#667085' }}>Total Package: 0</Typography> */}
                        </div>
                        {
                            nodes.total_downline > 0 &&
                            <div className='flex-c-m btn-small-square'>
                                <FiChevronRight className='fs-16' style={{color:'#222'}} />
                            </div>
                        }
                    </div>
                    <div className='w-full  p-t-20 p-b-8'>
                        <div className='txt-center clwhite flex-sb-m w-full'>
                            <p className='fs-13 txt-upper'>{t('stake.individual')}</p>
                            <p className='fs-13 p-b-3'><b>{currencyFormat(nodes.personal_sales, 2)}</b></p>
                        </div>
                        <div className='txt-center clwhite flex-sb-m w-full p-t-5'>
                            <p className='fs-13 txt-upper'>{t('stake.direct')}</p>
                            <p className='fs-13 p-b-3'><b>{currencyFormat(nodes.direct_sales, 2)}</b></p>
                        </div>
                        <div className='txt-center clwhite flex-sb-m w-full p-t-5'>
                            <p className='fs-13 txt-upper'>{t('stake.group')}</p>
                            <p className='fs-13 p-b-3'><b>{currencyFormat(nodes.group_sales, 2)}</b></p>
                        </div>
                    </div>
                    {/* <div className='txt-right p-r-10'>
                        <p className='fs-16'>{nodes.total_direct_downline}</p>
                        <p className='fs-12 cl-label'>{t('genealogy.totalDirect')}</p>
                    </div> */}
                    <p className='txt-truncate cl-label fs-12 p-b-2 p-t-10' style={{ fontWeight: 500 }}>{t('genealogy.totalDirect')} {t('genealogy.downline')}: <b>{nodes.total_direct_downline}</b></p>
                    <p className='txt-truncate cl-label fs-12' style={{ fontWeight: 500 }}>{t('genealogy.dateJoined', { 'date': moment(nodes.user_created_at).format('DD/MM/YYYY') })}</p>
                </div>
            </div>
        )
    }

    //Upline Dropdown Menu
    const [uplineMenu, setUplineMenu] = React.useState(null);
    const open = Boolean(uplineMenu);
    const handleOpenMenu = (event) => {
        setUplineMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setUplineMenu(null);
    };

    const handleSearchSelection = (data) => {
        searchUsername(data);
        handleCloseMenu();
    }
    
    const treeMap = () => {
        if (!loading)
        {
            return (
                <Grid container >
                    <Grid item xs={12} style={{ overflowX: "auto", }}>
                        <TreeView
                            className={styles.tree}
                            // defaultExpanded={[props.state.data.username ? `${tree?.id}` : `${id}`]}
                            style={{ position: 'relative', zIndex: 0 }}
                        >
                            {/* {props.state.data.username && tree.id !== id ? renderTree(tree) : _.map(tree.children, node => (renderTree(node)))} */}
                            {/* {_.map(tree.children, node => (renderTree(node)))} */}
                            {renderTree(tree)}
                        </TreeView>
                    </Grid>
                </Grid>
            )
        }
    }
    return (
        <div>
            {
                ( _.size(tree) > 0 || String(username) !== String(state.username)) ?
                    <Card style={{ border: 'none', marginBottom: 130, background: 'transparent' }} variant="outlined">
                        <CardContent style={{ borderRadius: 0, padding: 0 }}>
                            <div className='flex-sb-m p-b-10'>
                                <div className='txt-white'>
                                    <div className='fs-15 txt-upper p-b-5'>
                                        {
                                            openSearch ?
                                                <TextField
                                                    variant='standard'
                                                    InputLabelProps={{ shrink: true }}
                                                    name="search"
                                                    size="small"
                                                    placeholder={t('genealogy.username')}
                                                    fullWidth
                                                    value={state?.username || ''}
                                                    onChange={(event) => setState({ ...state, username: event.target.value })}
                                                    onKeyDown={(e) => (parseInt(e.keyCode) === 13 && searchUsername(state.username))}
                                                    sx={{ '& .MuiInputBase-root': { marginTop: '0px !important', height: 40 } }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => searchUsername(state.username)}
                                                                    edge="end"
                                                                >
                                                                    <BiSearch className='fs-20' style={{ color: '#8B8B8B' }} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    style={{ color: '#8B8B8B' }}
                                                />
                                                :
                                                <div className='dis-flex flex-m flex-col-sb p-l-10' style={{ flexDirection: 'row' }}>
                                                    {/* <b>{state.username}</b> */}
                                                    <Tooltip title={t('table.search')} arrow>
                                                        <IconButton onClick={() => setOpenSearch(true)}>
                                                            <BiSearch className='fs-20' style={{ color: theme.palette.primary.main }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                        }
                                    </div>
                                    {/* <p className='fs-12 cl-label'>{data?.downlines?.all_sponsor || 0} {t('genealogy.member')}</p> */}
                                </div>
                                
                                {
                                    _.size(searchUpline) > 0 && String(username) !== String(tree.username) && 
                                    <>
                                        {/* <Tooltip title={t('genealogy.back')} arrow>
                                            <PiArrowArcLeftBold color='primary' className='m-l-15 pointer' onClick={() => searchUsername()} />
                                        </Tooltip> */}
                                        <div
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleOpenMenu}
                                            className='pointer txt-right flex-r-m'
                                            style={{width:'30%'}}
                                        >
                                            {searchCurrentUser} <FiChevronDown style={{ marginLeft: 10}}/>
                                        </div>

                                        <Menu
                                            id="basic-menu"
                                            anchorEl={uplineMenu}
                                            open={open}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            onClose={handleCloseMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            {
                                                _.size(searchUpline) > 0 &&
                                                _.map(searchUpline, (user) => (
                                                    <MenuItem value={user?.username} key={user.id} onClick={ () => handleSearchSelection(user?.username) } >{user?.username}</MenuItem>
                                                ))
                                            }
                                        </Menu>
                                    </>
                                }
                            </div>
                            { treeMap() }
                        </CardContent>
                    </Card>
                    :
                    <Card style={{ border: 'none', background: 'transparent' }} variant="outlined">
                        <CardContent className='txt-center' style={{ height: 400, borderRadius: 0 }}>
                            <div className='p-t-40'>
                                <img src='/images/general/no-genealogy.png' alt='no-transaction' style={{ width: 180 }} loading='lazy' />
                            </div>
                            <p className='txt-white fs-18 p-t-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('genealogy.noRecord')}</p>
                            <p className='txt-white fs-14 p-t-8' style={{ color: '#D0D5DD' }}>{t('genealogy.noRecordDetails')}</p>
                        </CardContent>
                    </Card>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    tree: {
        flexGrow: 1,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        color: '#c1c1c1',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        marginLeft: 10,
        textTransform: "lowercase"
    },
    labelSubText: {
        marginLeft: 10,
        fontStyle: 'italic'
    },
    downlineBoxDesign: {
        position: 'relative',
        marginBottom: 15,
        padding: '15px 15px 15px 0px',
        borderRadius: 15,
        backgroundColor: '#ffffff1f',
        boxShadow: 'inset 1px 1px 1px 0 #fff3, inset -2px -2px 1px 0 #dbfffb47'
    },
    subline: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '-0.16px',
        color: '#98A2B3',
        fontFamily: 'sans-serif'
    },
    downlineMemberUsername: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#FFF'
    },
    disabledCursor: {
        cursor: 'default',
        pointerEvents: 'none'
    }
}));