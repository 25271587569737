import { Button, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { currencyFormat } from '@utils/Tools';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { BiDollarCircle } from "react-icons/bi";
import { MdAddCard, MdCurrencyExchange, MdOutlineVideogameAsset, MdSend } from "react-icons/md";
import { storePaths } from '@utils/Tools';

import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { FiEye, FiEyeOff } from 'react-icons/fi';
import 'swiper/css/bundle';

const WALLET_COLOUR = {
    1: 'gradient-main',
    2: 'gradient-theme',
    3: 'gradient-main'
}

const WALLET_ICON = {
    1: 'logo-awallet.png',
    2: 'logo-usdt.png',
    3: 'logo-awallet.png'
}

export default function WalletDashboard(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const {state, setState} = props
    const [slideView, setSlideView] = useState(0);




    return (
        <div className='flex-c-m flex-col w-full' style={{marginTop:-40}}>
          
                {
                    _.size(state.wallets) > 0 && _.map(state.wallets, (detail, index) => {
                        return (
                                <div key={index} className=' pos-relative p-t-70 pointer' style={{ width: 300, maxWidth: '85%', margin: '0 auto' }} onClick={() => navigate('/wallet-listing', { state: { from: storePaths(location) } })} >
                                    <div className={`${WALLET_COLOUR[detail.id]}`} style={{ height: 150, transform: 'skew(1deg, 353deg)', width: '90%', borderRadius: 10 }}></div>
                                    <div className='pos-absolute w-full' style={{ bottom: -16 }}>
                                        <div style={{ borderRadius: 10, height: 150 }}>
                                            <div className='glass-box txt-white w-full flex-sb-m flex-col' style={{ height: 150, boxShadow: 'rgba(255, 255, 255, 0.14) 0px 4px 4px 0px inset, inset -1px -2px 3px 0 #ffffff4d, inset 3px 0px 3px 0 #ffffff4d', backdropFilter: 'blur(5.5px)' }}>
                                                <div className='w-full flex-r-m' style={{ opacity: 0.5 }}>
                                                    <img src={`/images/wallet/${WALLET_ICON[detail.id]}`} alt='wallet' style={{ width: 50, filter: ' drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15))' }} />
                                                </div>

                                                <div className='p-t-10 p-b-10 w-full' style={{ textShadow: '1px 1px 1px #0000003b' }}>
                                                    <p className='txt-upper fs-15 p-b-5'>{detail.name[i18n.resolvedLanguage]} 
                                                        {
                                                            state.walletDecimal === 2 ?
                                                                <FiEye className='fs-12 m-l-10 pointer' onClick={() => setState({ ...state, walletDecimal: 4 })} />
                                                                :
                                                                <FiEyeOff className='fs-12 m-l-10 pointer' onClick={() => setState({ ...state, walletDecimal: 2 })} />
                                                        }
                                                    </p>
                                                    <p className='fs-20'><b>{currencyFormat(detail.balance * 1, state.walletDecimal)}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )
                    })}
        
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    funcButton: {
        background: 'linear-gradient(90deg, #55D3FF,#684EFF)',
        padding: 2,
        marginX: 10,
        width: 64,
        height: 64,
        flexDirection: 'column',
        zIndex: 1,
        borderRadius: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        color: '#ffffff'
    }
}));