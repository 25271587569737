
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { currencyFormat } from '@utils/Tools';
import { Button, Dialog, Slide, DialogTitle, DialogContent } from '@mui/material';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { FiX } from "react-icons/fi";


const TransactionHistoryDialog = props => {
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { dialog, handleCloseDialog } = props;


    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });


    const detailsSwitch = (type, data) => {
        switch (type) {
            case 'WalletAction':
                switch (data.transaction_type) {
                    case 'withdraw':
                        return (
                            <div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('title.cryptoAddress')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable?.details?.toAddress}</p>
                                </div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.txid')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable?.details?.txid}</p>
                                </div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('user.status')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable?.status_display?.details[i18n.resolvedLanguage]}</p>
                                </div>
                            </div>
                        );
                    default:
                        return null;
                }
            default:
                return null;
        }
    }
    

    return (
        <>
       

            <Dialog
                open={dialog.open}
                onClose={handleCloseDialog}
                // TransitionComponent={Transition}
                keepMounted
                // value={props.data}
            >
                <div className='gradient-theme pos-relative dialog-frame'>
                    {/* Triangle Edge Design */}
                    <div className='txt-white'>
                        <img src='/images/general/triangle.png' alt='element' style={{width:20, top:10, left:10}} className='pos-absolute'/>
                        <img src='/images/general/triangle.png' alt='element' style={{width:20, bottom:10, left:10, transform: 'scaleY(-1)'}} className='pos-absolute'/>
                        <img src='/images/general/triangle.png' alt='element' style={{width:20, bottom:10, right:10, transform: 'scale(-1)'}} className='pos-absolute'/>
                    </div>

                    {/* Button Close */}
                    <div className='pos-absolute flex-c-m dialog-btn-close-outer pointer' onClick={handleCloseDialog}>
                        <div className='flex-c-m dialog-btn-close-middle'>
                            <div className='bgwhite flex-c-m dialog-btn-close-inner'>
                            <FiX className='fs-23 clblack'/>
                            </div>
                        </div>
                    </div>

                    {/* Dialog Content */}
                    <div className='dialog-content-outer-bkg'>
                        <div className='p-tb-50 dialog-content-bkg'>
                            <div className='txt-center clwhite'>
                                <p className='fs-17 p-t-10 txt-upper'  style={{ fontWeight: 'normal' }}>{dialog.type === "commission" ? dialog.data?.bonus?.name[i18n.resolvedLanguage] : dialog.data?.transaction_code_display?.details[i18n.resolvedLanguage]}</p>
                                {/* <p className='fs-18 txt-title p-t-10' style={{ fontWeight: 'normal' }}>{t('wallet.transactionDetails')}</p> */}
                                { dialog?.data?.details?.to && <p className='fs-13 p-t-5'> {t('dialog.receiver')}: {dialog?.data?.details?.to}</p> }
                                { dialog?.data?.details?.from && <p className='fs-13 p-t-5'> {t('dialog.transferrer')}: {dialog?.data?.details?.from}</p> }
                                <p className='fs-13 cl-label p-t-5'>{dialog.type === 'wallet' ? dialog?.data?.updated_at : dialog?.data?.bonus_date}</p>
                            </div>
                            <div className='bgwhite' style={{height:5, borderRadius:48, width:60, margin:'15px auto 30px'}}></div>
                            <div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-12 cl-label'>{t('wallet.transactionId')}:</p>
                                    <p className='fs-13 txt-white' style={{ wordBreak: 'break-all' }}>{dialog.type === "wallet" ? dialog.data.ulid : dialog.data.id}</p>
                                </div>
                                {
                                    dialog.type === "wallet" ?
                                        <>{detailsSwitch(dialog.data?.transactionable_type, dialog.data)}</>
                                        :
                                        (_.includes(['roi-daily-bonus', 'roi-monthly-bonus'], dialog?.data?.bonus?.bonus_type)) ?
                                            <div className='p-b-20'>
                                                <p className='p-b-5 txt-upper fs-12 cl-label'>{t('Stake ID')}</p>
                                                {/* <p className='fs-13 txt-white' style={{ wordBreak: 'break-all' }}>{_.includes(i18n.resolvedLanguage, JSON.parse(dialog.data?.plan_name)) ? JSON.parse(dialog.data?.plan_name)[i18n.resolvedLanguage] : JSON.parse(dialog.data?.plan_name)?.['en']}{' (' + dialog.data?.plan_history_id + ')'}</p> */}
                                                <p className='fs-13 txt-white' style={{ wordBreak: 'break-all' }}>{dialog.data?.details?.stake_id || '-'}</p>
                                            </div>
                                            :
                                            <div className='p-b-20'>
                                                <p className='p-b-5 txt-upper fs-12 cl-label'>{t('wallet.downlineUsername')}</p>
                                                <div className='flex-m'>
                                                    <div className='p-r-8 flex-m'>
                                                        <img src='/images/rank/7.png' alt="rank" style={{ width: 20 }} />
                                                    </div>
                                                    <p className='fs-13 txt-white' style={{ wordBreak: 'break-all' }}>{dialog.data?.downline_username}</p>

                                                </div>
                                            </div>
                                }
                                {/* <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-12 cl-label'>{t('wallet.dateTime')}</p>
                                    <p className='fs-13 txt-white' style={{ fontWeight: 'bold' }}></p>
                                </div> */}
                            </div>
                            <div className='p-t-20 flex-sb-m txt-white' style={{ borderTop: '2px dashed #fff3' }}>
                                <p className='fs-12 txt-upper' style={{whiteSpace: 'break-spaces' }}>{t('wallet.totalAmount')}</p>
                                <p style={{ wordBreak: 'break-all' }} className='fs-17 txt-right'> {currencyFormat((dialog.data?.amount || 0) * 1, 4)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            </>
    )
}

export default TransactionHistoryDialog;