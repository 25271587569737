import React, { memo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, loginGame, postUrl } from '@utils/ApiAction';
import { storePlayerId } from '@actions';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import 'swiper/css/bundle';

import { TextField, InputAdornment, IconButton, Button, FormControl, Select, MenuItem, FormHelperText } from '@mui/material';

import { FiEye, FiEyeOff, FiUser, FiLock, FiShield } from "react-icons/fi";
import { BiCoinStack } from "react-icons/bi";
import { IoWalletOutline } from 'react-icons/io5';

import { storePaths } from '@utils/Tools';

import VerifyEmailDialog from '@components/VerifyEmailDialog';



const Stake = memo(() => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    const dispatch = useDispatch();
    const { accessToken, accountSetting } = useSelector(state => state.general);
    const { player_id, email_verified_at } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [walletTooltip, setWalletTooltip] = useState({});
    const [data, setData] = useState({});
    const [walletList, setWalletList] = useState(null);
    const [stakeSetting, setStakeSetting] = useState(null);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl('user/wallets').then(response => {
            if (response.status && isMountedRef.current) {
                const stakeWallet = _.find(response.data.wallet_list, { code: 'DW' });
                setData({ ...data, ...stakeWallet });
                setStakeSetting(response.data.stake_wallet);
                setWalletList(response.data.wallet_list);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

        return () => isMountedRef.current = false;
    }, []);

    const [state, setState] = useState({
        amount: '',
        combination_id: '',
        securityPassword: ''
        // code: '',
    });
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });
    const [inputErrors, setInputErrors] = useState({});

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //Dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };

    const combinationIdLabel = (_setting) => {
        let label = "";
        let percent = "";
        _.map(_setting, (_percent, _wallet) => {
            let existWallet = _.find(walletList, { id: parseInt(_wallet) });
            label += label ? " + " : "";
            label += existWallet?.name[i18n.resolvedLanguage];

            percent += percent ? " + " : "";
            percent += _percent+"%";

        })
        return label+` (${percent})`;
    }

    const stake = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            amount: state.amount,
            combination_id: state.combination_id,
            security_password: state.securityPassword,
            // code: state.code,
        }
        postUrl(`/user/stakes`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, amount: '', combination_id: 0, securityPassword: '' });
                addAlert('', t('success.stakeSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.stakeError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <div className='mobile-lr p-b-30'>
                <div className='p-t-30 pos-relative'>
                    <div className='p-lr-15 p-tb-30 pos-relative card-stake'>
                        <p className='txt-right txt-white pos-absolute' style={{ right: 20, top: 20 }}>{data.name && data.name[i18n.resolvedLanguage]?data.name[i18n.resolvedLanguage]:'-'}</p>
                        <div className='flex-c-m' style={{ backgroundImage: 'url(/images/stake/logo-texture.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain', minHeight: 110 }}>
                            <div className='txt-white txt-center'>
                                <p className='txt-upper fs-12'>{t('stake.availableBalance')}</p>
                                <p className='fs-25'><b>{currencyFormat(parseFloat(data?.balance || 0), 2)}</b></p>
                            </div>
                        </div>
                        <div className='card-hightlight pos-absolute' style={{top:0}}></div>
                        <div className='card-hightlight pos-absolute' style={{bottom:0}}></div>
                    </div>
                    <div className='pos-absolute' style={{ width: 65, bottom: -27, left: 8, transform: 'rotate(326deg)', filter: 'drop-shadow(2px 4px 2px #0002)', }}>
                        <img src='/images/wallet/coin-w.png' alt='coin' />
                    </div>
                    <div className='pos-absolute' style={{ width: 57, bottom: -10, right: 8, transform: 'rotate(44deg)', filter: 'drop-shadow(rgba(0, 0, 0, 0.133) 2px 4px 2px)' }}>
                    <img src='/images/wallet/coin-w.png' alt='coin' />
                    </div>
                </div>

                <div className='p-t-30'>
                    <div className='card-shadow'/>
                </div>
            </div>
            {/* //////////////////////////////      Form      ////////////////////////////////// */}
            <div className='textfield-border'>
                <div className='textfield-bkg '>
                    <p className='p-t-35 fs-21 txt-white' style={{ fontWeight: 'bold' }}>
                        {t('stake.staking')}
                    </p>
                    <div className='p-b-120'>
                        <div>
                            <TextField
                                variant="outlined"
                                fullWidth
                                placeholder={t('stake.amount')}
                                type="text"
                                color='white'
                                value={state.amount}
                                onChange={({ target }) => setState({ ...state, amount: target.value })}
                                helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                                error={inputErrors && inputErrors.amount ? true : false}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BiCoinStack className="img-style fs-18" style={{ color: '#ffffff' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div>
                            <FormControl fullWidth error={inputErrors && inputErrors.combination_id ? true : false}>
                            <Select
                                value={state?.combination_id || 0}
                                name="combination_id"
                                onChange={({ target }) => setState({ ...state, combination_id: target.value })}
                                displayEmpty
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IoWalletOutline className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')}`} --- </MenuItem>
                                {
                                    _.map(stakeSetting, (_setting, _combination_id) => {
                                        // return <MenuItem key={_combination_id} value={_combination_id}>{data?.name[i18n.language] || data?.name?.en}</MenuItem>
                                        return <MenuItem key={_combination_id} value={_combination_id}>{combinationIdLabel(_setting)}</MenuItem>
                                    })
                                }
                            </Select>
                            {
                                inputErrors && inputErrors?.combination_id &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.combination_id}</FormHelperText>
                            }
                            </FormControl>
                        </div>
                        <div>
                            <TextField
                                variant="outlined"
                                fullWidth
                                placeholder={t('user.securityPassword')}
                                type={showPassword.securityPassword ? 'text' : 'password'}
                                color='white'
                                value={state.securityPassword}
                                onChange={({ target }) => setState({ ...state, securityPassword: target.value })}
                                helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                error={inputErrors && inputErrors.security_password ? true : false}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('securityPassword')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword.securityPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        {/* <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('user.2FA')}
                            type='text'
                            color='white'
                            value={state.code}
                            onChange={({ target }) => setState({ ...state, code: target.value })}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiShield className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),

                            }}
                        />
                    </div> */}

                        <div className='p-t-30'>
                            <Button variant="contained" onClick={stake} fullWidth ><p className='txt-upper' style={{ fontWeight: 600 }}>{t('title.stake')}</p></Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

const useStyles = makeStyles((theme) => ({
    iconHoverStyle: {
        color: '#667085',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },

    walletBoxStyle: {
        flexDirection: 'column',
        borderRadius: 18,
        backdropFilter: 'blur(40px)',
        backgroundColor: '#F2F4F7',
        gap: 16,
        padding: '21px 57px 21px 15px',
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            padding: 15,
        },
        [theme.breakpoints.up('sm')]: {
            padding: 15,
        },
    },
}));

export default Stake;