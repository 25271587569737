import { Button, Grid, IconButton, InputAdornment, TextField, Typography, MenuItem, Select, FormHelperText, Container } from '@mui/material';
import { useTheme } from '@mui/styles';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import _ from 'lodash';
import COUNTRIES from '@utils/CountryList';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { useLocation, useNavigate } from 'react-router-dom';

// import CustomSelect from '@components/CustomSelect';

import { FiEye, FiEyeOff, FiUser, FiLock, FiMail, FiPhone } from 'react-icons/fi';

const Register = memo(() => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    const [searchParams] = useSearchParams();
    const [state, setState] = useState({
        username: '',
        name: '',
        email: '',
        mobile: '',
        country: '',
        password: '',
        passwordConfirmation: '',
        securityPassword: '',
        securityPasswordConfirmation: '',
        referralName: searchParams.get('referral') ? searchParams.get('referral') : '',
        // placementName: '',
        // placementPosition: 1,
    });
    const [success, setSuccess] = useState(false);
    let navigate = useNavigate();
    const [promo10U, setPromo10U] = useState(false);

    const [option, setOption] = useState({
        passwordShow: false,
        passwordConfirmationShow: false,
        securityPasswordShow: false,
        securityPasswordConfirmationShow: false
    });
    // const POSITION = [
    //     { 'code': 'left', 'value': 1, label: t('general.left') },
    //     { 'code': 'right', 'value': 2, label: t('general.right') }
    // ];

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const handleSubmit = () => {
        setLoading(true);
        setInputErrors({});
        let params = {
            name: state.name,
            username: state.username,
            email: state.email,
            mobile: state.mobile,
            country: state.country,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            security_password: state.securityPassword,
            security_password_confirmation: state.securityPasswordConfirmation,
            referral_name: state.referralName,
            // placement_name: state.placementName,
            // placement_position: state.placementPosition
        }

        postUrl('/register', params).then(response => {
            setLoading(false);
            if (response.status) {
                setSuccess(true)
                addAlert('', response.message, 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let promo_date = moment(moment().format("YYYY-MM-DD HH:mm:ss")).isBetween('2024-02-10 00:00:00', '2024-02-12 23:59:59');
        if (promo_date) {
            setPromo10U(true);
        }
    }, [])

    return (
        success === true ?
            <div>
                <div className='flex-c-m w-full' style={{ marginTop: 0, height: 300, backgroundImage: 'url("/images/background/background.png")', backgroundSize: 'cover', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat' }}>
                    <img src="/logo.png" className='m-b-84' style={{ height: 130, width: 'auto' }} alt="logo" />
                </div>
                <div className='textfield-border' style={{ marginTop: -47, }}>
                <div className=' pos-relative textfield-bkg p-t-70' >
                        <div className='pos-absolute' style={{ width: 73, top: -41, zIndex: 2, transform: 'rotate(325deg)', right: 30, filter: 'drop-shadow(0 4px 6px #0008)' }}>
                            <img src='/images/wallet/coin-w-theme.png' alt="coin" />
                        </div>

                        <div>
                            <Typography style={{ textAlign: 'center', fontSize: 30, fontWeight: 600 }}>{t('user.registerSuccess')}</Typography>
                            <Typography style={{ textAlign: 'center', fontSize: 16 }}>{t('user.registerSuccessSubline')}</Typography>
                        </div>
                        <div className='p-t-30'>
                            <Button variant="contained" onClick={() => navigate("/login")} fullWidth >{t('general.signIn')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            :
            <>
                
            <div>
                <div className='flex-c-m w-full' style={{ marginTop: 0, height: 300, backgroundImage: 'url("/images/background/background.png")', backgroundSize: 'cover', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat' }}>
                    <img src="/logo.png" className='m-b-84' style={{ height: 130, width: 'auto' }} alt="logo" />
                </div>
                <div className='textfield-border' style={{ marginTop: -47, }}>
                <div className=' pos-relative textfield-bkg' >
                        <div className='pos-absolute' style={{ width: 73, top: -41, zIndex:2, transform: 'rotate(325deg)', right: 30, filter: 'drop-shadow(0 4px 6px #0008)' }}>
                            <img src='/images/wallet/coin-w-theme.png' alt="coin" />
                        </div>
                        <div>
                        {/* style={{maxHeight:'calc(100vh - 361px', overflowY:'auto'}} */}
                            <p className='p-t-35 fs-21 txt-white' style={{ fontWeight: 'bold' }}>
                                {t('title.register')}
                            </p>
                            {/* <p className='fs-15 lh-14' style={{ color: theme.palette.textColor }} >Lorem ipsum dolor sit amet consectetur.</p> */}

                            <form style={{overflowY: 'hidden', marginBottom: 30 }}>
                                <Helmet>
                                    <meta name="description" content={promo10U ? "Sign Up Now for 10U Bonus" : t('general.previewLinkDesc')} />
                                    <meta name="og:url" content={window.location.href} />
                                    {/* <meta name="og:title" content={} /> */}
                                    <meta property="og:type" content="website" />
                                    <meta name="og:description" content={promo10U ? "Sign Up Now for 10U Bonus" : t('general.previewLinkDesc')} />
                                    <meta property="og:image" content="images/general/angpau.png" />
                                    <meta name="twitter:card" content="summary_large_image" />
                                </Helmet>
                                <div >
                                        {/* <div><Typography>{t('user.name')}</Typography></div> */}
                                        <div className='w-full'>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                name='name'
                                                placeholder={t('user.name')}
                                                onChange={handleChange}
                                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                                error={inputErrors && inputErrors.name ? true : false}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{ 
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                <InputAdornment position="start">
                                                    <FiUser className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                </InputAdornment>
                                            ), }}
                                                value={state?.name}
                                            />
                                        </div>
                                    <Grid item xs={12}>
                                        {/* <div><Typography>{t('user.username')}</Typography></div> */}
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                name='username'
                                                placeholder={t('user.username')}
                                                onChange={handleChange}
                                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                                error={inputErrors && inputErrors.username ? true : false}
                                                InputLabelProps={{ shrink: true }}
                                                value={state?.username}
                                                InputProps={{ 
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                <InputAdornment position="start">
                                                    <FiUser className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                </InputAdornment>
                                            ), }}
                                                
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <div><Typography>{t('user.email')}</Typography></div> */}
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                name='email'
                                                placeholder={t('user.email')}
                                                onChange={handleChange}
                                                required
                                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                                error={inputErrors && inputErrors.email ? true : false}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{ 
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                <InputAdornment position="start">
                                                    <FiMail className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                </InputAdornment>
                                            ), }}
                                                value={state?.email}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <div><Typography>{t('user.mobile')}</Typography></div> */}
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                name='mobile'
                                                placeholder={t('user.mobile')}
                                                onChange={handleChange}
                                                required
                                                helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                                error={inputErrors && inputErrors.mobile ? true : false}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{ 
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                <InputAdornment position="start">
                                                    <FiPhone className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                </InputAdornment>
                                            ), }}
                                                value={state?.mobile}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            style={{width: '100%' }}
                                            value={state?.country || 0}
                                            name="country"
                                            onChange={handleChange}
                                            displayEmpty
                                            error={inputErrors && inputErrors.country ? true : false}
                                        >
                                            <MenuItem key={0} value={0} disabled>--- {`${t('general.selectCountry')}`} --- </MenuItem>
                                            {
                                                _.map(COUNTRIES, (data, key) => {
                                                    return <MenuItem key={key} value={data?.value}>{data?.label}</MenuItem>
                                                })
                                            }
                                        </Select>
                                        {
                                            inputErrors && inputErrors?.country &&
                                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors?.country}</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <div><Typography>{t('user.referralName')}</Typography></div> */}
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                name='referralName'
                                                placeholder={t('user.referralName')}
                                                onChange={handleChange}
                                                helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                                                error={inputErrors && inputErrors.referral_name ? true : false}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{ 
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                <InputAdornment position="start">
                                                    <FiUser className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                </InputAdornment>
                                            ), }}
                                                value={state?.referralName}
                                                disabled={searchParams.get("referral") ? true : false}
                                            />
                                        </div>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='placementName'
                        label={t('user.placementName')}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.placement_name ? inputErrors.placement_name : ''}
                        error={inputErrors && inputErrors.placement_name ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomSelect
                        label={t('user.placementPosition')}
                        listing={POSITION}
                        list_label='label'
                        list_value='value'
                        placeholder={t('general.selectOption', { option: t('user.position') })}
                        helperText={inputErrors && inputErrors.placement_position ? inputErrors.placement_position : ''}
                        error={inputErrors && inputErrors.placement_position ? true : false}
                        displayEmpty={true}
                        value={state.placementPosition}
                        onChange={handleChange}
                        name="placementPosition"
                    />
                </Grid> */}
                                    <Grid item xs={12}>
                                        {/* <div><Typography>{t('user.password')}</Typography></div> */}
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                name='password'
                                                placeholder={t('user.password')}
                                                onChange={handleChange}
                                                type={option?.passwordShow ? 'text' : 'password'}
                                                InputProps={{
                                                    startAdornment: (
                                                <InputAdornment position="start">
                                                    <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                </InputAdornment>
                                            ), 
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => handlePasswordShow('passwordShow')}>
                                                                {option?.passwordShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true
                                                }}
                                                helperText={inputErrors && inputErrors.password ? inputErrors.password : t('user.loginPasswordFormat')}
                                                error={inputErrors && inputErrors.password ? true : false}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <div><Typography>{t('user.confirmPassword')}</Typography></div> */}
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                name='passwordConfirmation'
                                                placeholder={t('user.confirmPassword')}
                                                onChange={handleChange}
                                                type={option?.passwordConfirmationShow ? 'text' : 'password'}
                                                InputProps={{
                                                    startAdornment: (
                                                <InputAdornment position="start">
                                                    <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                </InputAdornment>
                                            ), 
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => handlePasswordShow('passwordConfirmationShow')}>
                                                                {option?.passwordConfirmationShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true
                                                }}
                                                helperText={inputErrors && inputErrors.confirm_password ? inputErrors.confirm_password : ''}
                                                error={inputErrors && inputErrors.confirm_password ? true : false}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    </Grid>
                                    {/* SECURITY PASSWORD */}
                                    <Grid item xs={12}>
                                        {/* <div><Typography>{t('user.securityPassword')}</Typography></div> */}
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                name='securityPassword'
                                                placeholder={t('user.securityPassword')}
                                                onChange={handleChange}
                                                type={option?.securityPasswordShow ? 'text' : 'password'}
                                                InputProps={{
                                                    startAdornment: (
                                                <InputAdornment position="start">
                                                    <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                </InputAdornment>
                                            ), 
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => handlePasswordShow('securityPasswordShow')}>
                                                                {option?.securityPasswordShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true
                                                }}
                                                helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : t('user.securityPasswordFormat') }
                                                error={inputErrors && inputErrors.security_password ? true : false}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <div><Typography>{t('user.securityPasswordConfirm')}</Typography></div> */}
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                name='securityPasswordConfirmation'
                                                placeholder={t('user.securityPasswordConfirm')}
                                                onChange={handleChange}
                                                type={option?.securityPasswordConfirmationShow ? 'text' : 'password'}
                                                InputProps={{
                                                    startAdornment: (
                                                <InputAdornment position="start">
                                                    <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                                </InputAdornment>
                                            ), 
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => handlePasswordShow('securityPasswordConfirmationShow')}>
                                                                {option?.securityPasswordConfirmationShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true
                                                }}
                                                helperText={inputErrors && inputErrors.security_password_confirmation ? inputErrors.security_password_confirmation : ''}
                                                error={inputErrors && inputErrors.security_password_confirmation ? true : false}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" style={{ marginTop: 20 }} >
                                        <Button type="button" variant="contained" onClick={handleSubmit} fullWidth >{t('button.submit')}</Button>
                                    </Grid>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            </>
    )
});

export default Register;