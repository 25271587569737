import React, { memo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, loginGame, postUrl } from '@utils/ApiAction';
import { storePlayerId } from '@actions';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import 'swiper/css/bundle';

import { Button, LinearProgress, Typography, Tooltip, ClickAwayListener, Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, } from '@mui/material';

import { FiUsers, FiUser, FiPlus } from "react-icons/fi";
import { storePaths } from '@utils/Tools';

import VerifyEmailDialog from '@components/VerifyEmailDialog';

const Stake = memo(() => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    const dispatch = useDispatch();
    const { accessToken, accountSetting } = useSelector(state => state.general);
    const { player_id, email_verified_at } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [walletTooltip, setWalletTooltip] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl('user/stakes').then(response => {
            if (response.status && isMountedRef.current) {
                setData({ ...data, ...response.data });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

        return () => isMountedRef.current = false;
    }, []);

    //Dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };

    return (
        <>
            <div className='mobile-lr p-b-50 p-t-100'>
                {/* <div className='button-small flex-m' style={{ width: 'fit-content' }}>
                    <FiPlus style={{ color: '#05122D' }} />
                    <p className='p-l-10'>Stake Now</p>
                </div> */}
            <div style={{ width: 95, margin: '0 auto' }}>
                <img src='/images/stake/stake-icon.png' alt='stake' />
            </div>
            <div className='txt-center'>
                <p className='txt-upper cl-label fs-12'>{t('stake.totalStake')}</p>
                <p className='txt-white fs-25 p-t-5'>
                    <b>{parseFloat(data?.sales?.group_sales || 0) + parseFloat(data?.sales?.personal_sales || 0)}</b>
                </p>
            </div>
            <div className='w-full flex-sb-m p-t-30'>
            <div className='flex-m p-lr-20' style={{width:'calc(50% - 2px)'}}>
                <div className='flex-c-m txt-white' style={{ width: 35, height: 35, background: '#8C52FF', borderRadius: 5 }}>
                <FiUsers style={{color:'#05122D'}}/>
                </div>
                <div className='p-l-8'>
                    <p className='fs-18 txt-white'>{currencyFormat(parseFloat(data?.sales?.group_sales || 0), 2)}</p>
                    <p className='txt-upper cl-label fs-11 p-t-3'>{t('stake.group')}</p>
                </div>
            </div>
            <div className='flex-m'>
                <div style={{height:30, width:2, background:'#fff2'}}></div>
                <div style={{height:30, width:2, background:'#0007'}}></div>
            </div>
            <div className='flex-m p-lr-20' style={{width:'calc(50% - 2px)'}}>
                <div className='flex-c-m txt-white' style={{ width: 35, height: 35, background: '#CB6CE6', borderRadius: 5 }}>
                <FiUser style={{color:'#05122D'}}/>
                </div>
                <div className='p-l-8'>
                    <p className='fs-18 txt-white'>{currencyFormat(parseFloat(data?.sales?.personal_sales || 0),2)}</p>
                    <p className='txt-upper cl-label fs-11 p-t-3'>{t('stake.individual')}</p>
                </div>
            </div>
            </div>

                <div className='p-t-30'>
                <Link component={RouterLink} to={"/staking"} underline='none' state={{ from: storePaths(location) }} >
                    <div className='button-large flex-c-m' >
                        <FiPlus style={{ color: '#05122D' }} />
                        <p className='p-l-10' style={{color:'#05122D'}}>{t('stake.stakeNow')}</p>
                    </div>
                    </Link>
                </div>
        </div>
         {/* //////////////////////////////      history      ////////////////////////////////// */}
         <div className='textfield-border'>
            <div className='textfield-bkg'>
                <p className='p-t-35 fs-21 txt-white' style={{ fontWeight: 'bold' }}>
                    {t('title.transactionHistory')}
                </p>
                {/* <p className='fs-15 lh-14' style={{ color: theme.palette.textColor }} >Lorem ipsum dolor sit amet consectetur.</p> */}
                {_.size(data?.listing?.data) <= 0 ?
                    <div className='txt-center' style={{ paddingTop: 60, paddingBottom: 120 }}>
                        <img src='/images/general/no-transaction.png' alt='no-transaction' style={{ width: 180 }} loading='lazy' />
                        <p className='txt-white fs-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('table.noRecord')}</p>
                    </div>
                    :
                    <>
                    {_.map(data.listing.data, (data, key) => {
                        return (
                            <div className='flex-sb-m w-full p-tb-15' key={key}>
                                <div className='p-r-15 max-w-full' style={{ width: 250 }}>
                                    <p className='txt-white'>{t('title.stake')}</p>
                                    <p className='txt-truncate cl-label fs-13 p-t-5'>{data.status_display[i18n.resolvedLanguage]}</p>
                                </div>
                                <div className='flex-m'>
                                    <div className='txt-right' style={{ width: 90 }}>
                                        <p className='txt-white' style={{ fontWeight: 'bold' }}>{currencyFormat(parseFloat(data.amount), 2)}</p>
                                        <p className='cl-label fs-13 p-t-5'>{data.created_at}</p>
                                    </div>
                                    {/* <div className='p-l-6'>
                                    <FiChevronRight className='txt-white'/>
                                    </div> */}
                                </div>
                            </div>
                        )
                    })}
                    </>
                }
                {/* onClick={handleClickOpenDialog} */}
{/*  
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Disagree</Button>
          <Button onClick={handleCloseDialog} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog> */}
                
                
            </div>
            </div>
            
        </>
    )
});

const useStyles = makeStyles((theme) => ({
    iconHoverStyle: {
        color: '#667085',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    iconStyle: {
        color: '#FFFFFF',
    },
    walletBoxStyle: {
        flexDirection: 'column',
        borderRadius: 18,
        backdropFilter: 'blur(40px)',
        backgroundColor: '#F2F4F7',
        // width: '50%',
        gap: 16,
        padding: '21px 57px 21px 15px',
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            padding: 15,
        },
        [theme.breakpoints.up('sm')]: {
            padding: 15,
        },
    },
}));

export default Stake;