import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Link, Slide } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineViewAgenda } from 'react-icons/md';

import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import { storePaths } from '@utils/Tools';

import Commission from './Commission';
import WalletDashboard from './WalletDashboard';
import CommissionDashboard from './CommissionDashboard';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="lh-0" p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

//Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Index(props) {
    const [state, setState] = useState({
        pageIndex: null,
        subPage: 'wallet',
        wallets: [],
        walletSetting: [],
        walletIndex: 0,
        commissions: [],
        commissionIndex: 0,
        totalComm:[],
        walletDecimal: 2,
    });

    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let location = useLocation();
    let navigate = useNavigate();
    let { page } = useParams();

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    //Dialog
    const [dialog, setDialog] = useState({
        open: false,
        data: []
    });
    const handleClickOpen = (data) => {
        setDialog({ ...dialog, open: true, data: data });
    };
    const handleClose = () => {
        setDialog({ ...dialog, open: false, data: [] });
    };

    useEffect(() => {
        if (page) {
            if (_.includes(['wallet', 'commission'], page)) {
                setState({
                    ...state,
                    pageIndex: page === 'commission' ? 1 : 0,
                    subPage: page,
                })
            }
        }
    }, [page]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        if (state.pageIndex !== null) {
            let apiUrl = '/user/wallets';
            let param = {};
            if (state.pageIndex === 0) {
            } else if (state.pageIndex === 1) {
                apiUrl = '/user/commissions';
                param = {brief:true};
            }

            getUrl(apiUrl, param).then(response => {
                if (response.status && isMountedRef.current) {
                    if (state.pageIndex === 0) {
                        setState({ ...state, wallets: Object.values(response.data.wallet_list), walletSetting: response.data.wallet_setting });
                    } else if (state.pageIndex === 1) {
                        setState({ ...state, commissions: Object.values(response.data.comm), totalComm: response.data.total});
                    }
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [state.pageIndex]);

    const displayPage = () => {
        if (state.subPage === 'wallet') {
            return <WalletDashboard state={state} setState={setState} />
        }
        else if (state.subPage === 'commission') {
            return <CommissionDashboard state={state} setState={setState} />
        }
    }

    const handleChange = (value) => {
        navigate(`/wallets/${value === 1 ? 'commission' : 'wallet'}`);
    };

    const detailsSwitch = (type, data) => {
        switch (type) {
            case 'WalletAction':
                switch (data.transaction_type) {
                    case 'withdraw':
                        return (
                            <div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('title.cryptoAddress')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable?.details?.toAddress}</p>
                                </div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('wallet.txid')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable?.details?.txid}</p>
                                </div>
                                <div className='p-b-20'>
                                    <p className='p-b-5 txt-upper fs-13' style={{ color: '#737e94' }}>{t('user.status')}</p>
                                    <p className='fs-15' style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{data.wallet_transactionable?.status_display?.details[i18n.resolvedLanguage]}</p>
                                </div>
                            </div>
                        );
                    default:
                        return null;
                }
            default:
                return null;
        }
    }

    const displayStatus = (status) => {
        let img_text = '';
        switch (status) {
            case 20:
            case 40:
                img_text = 'pending';
                break;
            case 60:
                img_text = 'success';
                break;
            default:
                img_text = 'failed';
                break;
        }
        return <img src={`/images/wallet/transaction-${img_text}.png`} alt="status" className={styles.iconStyle} />;
    }

    return (
        <div className='p-b-150 p-t-95' style={{ minHeight: '100vh', overflowY: 'hidden' }}>
            <Box>
                <Box className="p-lr-15 p-t-50">
                    {!_.isNull(state.pageIndex) ? <>
                        <div className='p-lr-35'>
                            <div className='btn-tab-bkg flex-m'>
                                <div
                                    value={0}
                                    onClick={() => handleChange(0)}
                                    className={`${state.pageIndex === 0 ? 'btn-tab-selected' : 'btn-tab-default'} flex-c-m `}>
                                    <p className='fs-14 inter-normal'>{t('title.wallet')}</p>
                                </div>
                                <div
                                    value={1}
                                    onClick={() => handleChange(1)}
                                    className={`${state.pageIndex === 1 ? 'btn-tab-selected' : 'btn-tab-default'} flex-c-m `}>
                                    <p className='fs-14 inter-normal'>{t('title.commission')}</p>
                                </div>
                            </div>
                        </div>
                 
                        <div className='p-tb-20'>
                            {/* /////////  Wallet /////////*/}
                            <TabPanel value={state.pageIndex} index={0} dir={theme.direction}>
                                <Grid item xs={12}>
                                    {displayPage()}
                                </Grid>
                            </TabPanel>

                            {/* /////////  Commission  /////////*/}
                            <TabPanel value={state.pageIndex} index={1} dir={theme.direction}>
                                <Grid item xs={12}>
                                    {displayPage()}
                                </Grid>
                            </TabPanel>
                        </div>
                    </> : null}
                </Box>
            </Box>

           
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    selectedButton: {
        color: '#05122D',
        padding: '8px 12px 8px 12px',
        minWidth: 0,
        width: '150px',
        borderRadius: 48,
background: '#5CE1E6',
boxShadow: '0px 8px 10px 0px rgba(117, 238, 255, 0.24), 0px -4px 7px 0px rgba(27, 143, 150, 0.15) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.45) inset'
    },
    button: {
        borderRadius: '50px',
        color: '#5CE1E6',
        backgroundColor: '#05122D',
        background: '#05122D',
        padding: '8px 12px 8px 12px',
        minWidth: 0,
        width: '150px',
        border:'2px solid #5CE1E6',
        boxShadow:'none'
    },
    iconStyle: {
        height: 15,
        width: 14,
        marginLeft: 10
    }
}));