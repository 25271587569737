import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import { currencyFormat, truncate } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FiEye, FiEyeOff, FiLock, FiMessageSquare } from 'react-icons/fi';
import { IoWalletOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";

const CARD_COLOUR = {
    1: 'card-stake-2',
    2: 'card-stake'
}

const COIN = {
    1: 'coin-a.png',
    2: 'coin-w.png'
}

export default function Withdrawal(props) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const [inputErrors, setInputErrors] = useState({});
    const [refresh, setRefresh] = useState({});
    const { crypto_address } = useSelector(state => state.user);

    const [state, setState] = useState({
        wallet: [],
        rule: [],
        amount: '',
        remark: '',
        securityPassword: ''
    });
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setLoading(true);
        getUrl(`/wallets/setting/withdrawal/${id}`).then(response => {
            if (response.status) {
                setState({ ...state, wallet: response.data.balance, rule: response.data.rule });
            }else{
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [refresh]);

    const withdraw = (event) => {
        setLoading(true);
        event.preventDefault();
        setInputErrors();
        let params = {
            wallet_id: id,
            amount: state.amount,
            remark: state.remark,
            security_password: state.securityPassword,
        }
        postUrl(`/wallets/withdrawal`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, username: '', amount: '', remark: '', securityPassword: '' });
                addAlert('', t('success.withdrawSuccess'), 'success', '');
                setRefresh(!refresh);
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.withdrawError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <div className='mobile-lr p-b-30'>
                <div className='p-t-37 pos-relative'>
                    <div className={`p-lr-15 p-tb-30 pos-relative ${CARD_COLOUR[id]}`}>
                        <p className='txt-right txt-white pos-absolute' style={{ right: 20, top: 20 }}>{state.wallet?.name && state.wallet?.name[i18n.resolvedLanguage] ? state.wallet?.name[i18n.resolvedLanguage] : '-'}</p>
                        <div className='flex-c-m' style={{ backgroundImage: 'url(/images/stake/logo-texture.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain', minHeight: 110 }}>
                            <div className='txt-white txt-center'>
                                <p className='txt-upper fs-12'>{t('withdrawal.balance')}</p>
                                <p className='p-t-10 fs-25'><b>{currencyFormat(parseFloat(state?.wallet?.balance || 0), 2)}</b></p>
                            </div>
                        </div>
                        <div className='card-hightlight pos-absolute' style={{ top: 0 }}></div>
                        <div className='card-hightlight pos-absolute' style={{ bottom: 0 }}></div>
                    </div>
                    <div className='pos-absolute' style={{ width: 65, bottom: -27, left: 8, transform: 'rotate(326deg)', filter: 'drop-shadow(2px 4px 2px #0002)', }}>
                        <img src={`/images/wallet/${COIN[id]}`} alt='coin' />
                    </div>
                    <div className='pos-absolute' style={{ width: 57, bottom: -10, right: 8, transform: 'rotate(44deg)', filter: 'drop-shadow(rgba(0, 0, 0, 0.133) 2px 4px 2px)' }}>
                        <img src={`/images/wallet/${COIN[id]}`} alt='coin' />
                    </div>
                </div>

                <div className='p-t-30'>
                    <div className='card-shadow' />
                </div>
            </div>
            {/* //////////////////////////////      Form      ////////////////////////////////// */}
            <div className='textfield-border'>
            <div className='textfield-bkg'>
                <p className='p-t-35 fs-21 txt-white' style={{ fontWeight: 'bold' }}>
                    {t('wallet.withdraw')}
                </p>
                <Box className='p-b-120' component="form" onSubmit={withdraw}>
                    <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('gameTopup.amount')}
                            type="text"
                            color='white'
                            name='amount'
                            value={state.amount}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                            error={inputErrors && inputErrors.amount ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <BiCoinStack className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <p style={{ color: '#667085' }} className='txt-left p-t-8 fs-14'>{t('withdrawal.minimumWithdraw') + (state.rule?.min_amount || 0)}</p>
                    </div>
                    <div>
                        <TextField
                            variant="standard"
                            fullWidth
                            name="crypto_address"
                            // label={t('user.cryptoAddress')}
                            placeholder={t('user.cryptoAddress')}
                            type='text'
                            value={truncate(crypto_address||'', 22) || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.crypto_address ? inputErrors.crypto_address : ''}
                            error={inputErrors && inputErrors.crypto_address ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IoWalletOutline className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                            }}
                            disabled
                        />
                        {!crypto_address ? <p className='p-t-8 txt-left fs-14' style={{ color: '#667085' }}>{t('error.noCryptoAddress')}</p> : null}
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('wallet.remark')}
                            type="text"
                            color='white'
                            name='remark'
                            value={(state.remark)}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                            error={inputErrors && inputErrors.remark ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiMessageSquare className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('user.securityPassword')}
                            type={showPassword.securityPassword ? 'text' : 'password'}
                            color='white'
                            value={state.securityPassword}
                            name='securityPassword'
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                            error={inputErrors && inputErrors.security_password ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('securityPassword')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.securityPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    {/* <div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={t('user.2FA')}
                            type='text'
                            color='white'
                            value={state.code}
                            onChange={({ target }) => setState({ ...state, code: target.value })}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiShield className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),

                            }}
                        />
                    </div> */}
                    <div className='p-t-30'>
                        <Button variant="contained" type="submit" fullWidth ><p style={{ fontWeight: 600 }}>{t('wallet.withdraw')}</p></Button>
                    </div>
                </Box>
            </div>
            </div>
        </>
    )
}

const useStyles = makeStyles(theme => ({

}));