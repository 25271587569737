import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { FiEye, FiEyeOff, FiLock } from "react-icons/fi";

const SecurityPassword = () => {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState({
        currentPassword: false,
        password: false,
        passwordConfirmation: false,
    });
    const [state, setState] = useState({
        currentPassword: "",
        password: "",
        passwordConfirmation: "",
    });
    const [inputErrors, setInputErrors] = useState({});

    const { addAlert, setLoading } = useNotificationLoading();
    const styles = useStyles();
    const { t } = useTranslation();
    const { id } = useSelector(state => state.user);

    const handleChange = ({ target }) => {
        const { name, value } = target;

        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const updatePassword = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            current_password: state.currentPassword,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
        }
        putUrl(`/user/security-password`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, currentPassword: "", password: "", passwordConfirmation: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className=" pos-relative w-full flex-col flex-m" style={{ justifyContent: 'flex-start' }}>
            <div className='setting-subpage-header' style={{ backgroundImage: 'url("/images/setting/security-password.png")' }}>
            </div>
            <div className='textfield-border' style={{ marginTop: -67, }}>
                <div className=' pos-relative textfield-bkg txt-white' >
                    <div className='p-t-50'>
                        <p className='fs-17 inter-bold'>{t('title.securityPassword')}</p>
                        <p className='inter-normal fs-13 cl-label lh-12 p-t-5'>{t('settingDescription.password')}</p>
                    </div>

                    <div>
                        <div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name="currentPassword"
                                // label={t('user.oldPassword')}
                                placeholder={t('user.oldPassword')}
                                type={showPassword.currentPassword ? 'text' : 'password'}
                                value={state.currentPassword || ''}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.current_password ? inputErrors.current_password : t('user.securityPasswordFormat')}
                                error={inputErrors && inputErrors.current_password ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClickShowPassword('currentPassword')}
                                            // onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword.currentPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <p className="fs-12 p-t-10" style={{ fontWeight: 500, color: '#98A2B3' }}>
                                {t('user.currentSecurityPasswordNote')}
                            </p>
                        </div>
                        <TextField
                            variant="standard"
                            fullWidth
                            name="password"
                            // label={t('user.newSecurityPassword')}
                            placeholder={t('user.newSecurityPassword')}
                            type={showPassword.password ? 'text' : 'password'}
                            value={state.password || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('password')}
                                        // onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword.password ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            variant="standard"
                            fullWidth
                            name="passwordConfirmation"
                            // label={t('user.confirmSecurityPassword')}
                            placeholder={t('user.confirmSecurityPassword')}
                            type={showPassword.passwordConfirmation ? 'text' : 'password'}
                            value={state.passwordConfirmation || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('passwordConfirmation')}
                                        // onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword.passwordConfirmation ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className='p-t-40'>
                            <Button variant="contained" onClick={updatePassword} style={{ paddingTop: 10, paddingBottom: 10 }} fullWidth>{t('button.save')}</Button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },

}));

export default SecurityPassword