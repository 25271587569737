import { Button, Grid, Box, Link, Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';


import { BiDollarCircle } from "react-icons/bi";
import { MdAddCard, MdCurrencyExchange, MdOutlineVideogameAsset, MdSend } from "react-icons/md";
import { storePaths } from '@utils/Tools';

import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { FiEye, FiChevronRight } from 'react-icons/fi';
import 'swiper/css/bundle';
import TransactionHistoryDialog from '@components/TransactionHistoryDialog';

const WALLET_COLOUR = {
    1: 'gradient-main shadow-menu-main',
    2: 'gradient-theme shadow-menu-theme',
    3: 'gradient-main shadow-menu-main',
}
const WALLET_ICON = {
    1: 'logo-awallet.png',
    2: 'logo-usdt.png',
    3: 'logo-awallet.png'
}

export default function WalletListing() {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({walletIndex: 0, pageIndex:0, walletSetting:[], wallets:[]});
    const [slideView, setSlideView] = useState(0);
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [dialog, setDialog] = useState({open:false, data:[], type:"wallet"});

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        if (state.pageIndex !== null) {
            getUrl('/user/wallets').then(response => {
                if (response.status && isMountedRef.current) {
                    if (state.pageIndex === 0) {
                        setState({ ...state, wallets: Object.values(response.data.wallet_list), walletSetting: response.data.wallet_setting });
                    } else if (state.pageIndex === 1) {
                        setState({ ...state, commissions: Object.values(response.data) });
                    }
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [state.pageIndex]);

    //Dialog

   
    const displayStatus = (status) => {
        let img_text = '';
        switch (status) {
            case 20:
            case 40:
                img_text = 'pending';
                break;
            case 60:
                img_text = 'success';
                break;
            default:
                img_text = 'failed';
                break;
        }
        return <img src={`/images/wallet/transaction-${img_text}.png`} alt="status" className={styles.iconStyle} />;
    }
    const handleClickOpenDialog = (data) => {
        setDialog({ ...dialog, open: true, data: data });
    };

    const handleCloseDialog = () => {
        setDialog({ ...dialog, open: false, data: [] });
    };

    
    useEffect(() => {
        let actionCount = 0;
        if (_.includes(state.walletSetting?.['deposit-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id)) {
            actionCount++;
        }
        if (_.includes(state.walletSetting?.['game-topup-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id)) {
            actionCount++;
        }
        //  transfer
        if (!!_.find(state.walletSetting['transfer-rules']?.[state.wallets[state.walletIndex]?.id], { allow_others: true })) {
            actionCount++;
        }
        //  convert
        if (!!_.find(state.walletSetting['transfer-rules']?.[state.wallets[state.walletIndex]?.id], { allow_self: true })) {
            actionCount++;
        }
        if (_.includes(state.walletSetting?.['withdraw-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id)) {
            actionCount++;
        }
        setSlideView(actionCount >= 4 ? 3.3 : actionCount);
    }, [state.walletIndex, state.walletSetting, state.wallets])
    

    return (
        <div>
        <div className='w-full p-lr-35 p-b-50'>
            <div className='w-full'>
                <Swiper
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    onSlideChange={(e) => setState({ ...state, walletIndex: e.activeIndex })}
                >
                    {
                        _.size(state.wallets) > 0 && _.map(state.wallets, (detail, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="flex-c-m flex-col">
                                        <p className='txt-white fs-12 txt-upper inter-normal p-t-5'>{detail.name[i18n.resolvedLanguage]}</p>
                                    </div>
                                    <div className='p-t-40'>
                                        <div className='glass-box-hightlight' style={{ width: 320, maxWidth: '90%', margin: '0 auto' }}>
                                            <div style={{ background: '#16114e', borderRadius: 10 }}>
                                                <div className='glass-box txt-white flex-sb-m p-b-80 p-t-30'>
                                                    <div style={{ textShadow: '1px 1px 1px #0000003b' }}>
                                                        <p className='txt-upper fs-13 p-b-5'>{detail.name[i18n.resolvedLanguage]}
                                                            {
                                                                state.walletDecimal === 2 ?
                                                                    <FiEye className='fs-12 m-l-10 pointer' onClick={() => setState({ ...state, walletDecimal: 4 })} />
                                                                    :
                                                                    <FiEye className='fs-12 m-l-10 pointer' onClick={() => setState({ ...state, walletDecimal: 2 })} />
                                                            }
                                                        </p>
                                                        <p className='fs-20'><b>{currencyFormat(detail.balance * 1, state.walletDecimal)}</b></p>
                                                    </div>
                                                    <div style={{ opacity: 0.5 }}>
                                                        <img src={`/images/wallet/${WALLET_ICON[detail.id]}`} alt='wallet' style={{ width: 50, filter: ' drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15))' }} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>

            {
                (_.size(state.wallets) > 0 && state.walletSetting) ?
                
                    <div className={`${WALLET_COLOUR[state.wallets[state.walletIndex]?.id]} wallet-menu`} style={{zIndex:2, width: 390, margin: '-47px auto 0', maxWidth: '100%'}}>
                        <Swiper
                            slidesPerView={slideView}
                            pagination={true}
                            style={{ width: '100%' }}
                        >
                            {
                                _.includes(state.walletSetting?.['deposit-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id) ?
                                    <SwiperSlide>
                                        <div className='flex-c-m flex-col txt-white pointer' onClick={() => navigate('/wallet/deposit', { state: { from: storePaths(location) } })} >
                                            <MdAddCard style={{ height: 28, width: 'auto' }} />
                                            <p className='p-t-5 fs-10 txt-upper inter-bold'>{t('wallet.deposit')}</p>
                                        </div>
                                    </SwiperSlide>
                                    : null
                            }
                            {
                                _.includes(state.walletSetting?.['game-topup-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id) ?
                                    <SwiperSlide>
                                        <div className='flex-c-m flex-col txt-white pointer' onClick={() => navigate(`/wallet/game-topup/${state.wallets[state.walletIndex].id}`, { state: { from: storePaths(location) } })}>
                                            <MdOutlineVideogameAsset style={{ height: 28, width: 'auto' }} />
                                            <p className='p-t-5 fs-10 txt-upper inter-bold'>{t('wallet.gameTopup')}</p>
                                        </div>
                                    </SwiperSlide>
                                    : null
                            }
                            {
                                !!_.find(state.walletSetting['transfer-rules']?.[state.wallets[state.walletIndex]?.id], { allow_others: true }) ?
                                    <SwiperSlide>
                                        <div className='flex-c-m flex-col txt-white pointer' onClick={() => navigate(`/wallet/transfer/${state.wallets[state.walletIndex].id}`, { state: { from: storePaths(location) } })}>
                                            <MdSend style={{ height: 28, width: 'auto' }} />
                                            <p className='p-t-5 fs-10 txt-upper inter-bold'>{t('wallet.transfer')}</p>
                                        </div>
                                    </SwiperSlide>
                                    : null
                            }
                            {
                                !!_.find(state.walletSetting['transfer-rules']?.[state.wallets[state.walletIndex]?.id], { allow_self: true }) ?
                                    <SwiperSlide>
                                        <div className='flex-c-m flex-col txt-white pointer' onClick={() => navigate(`/wallet/convert/${state.wallets[state.walletIndex].id}`, { state: { from: storePaths(location) } })}>
                                            <MdCurrencyExchange style={{ height: 28, width: 'auto' }} />
                                            <p className='p-t-5 fs-10 txt-upper inter-bold'>{t('title.convert')}</p>
                                        </div>
                                    </SwiperSlide>
                                    : null
                            }
                            {
                                _.includes(state.walletSetting?.['withdraw-rules']?.wallet_ids, state.wallets[state.walletIndex]?.id) ?
                                    <SwiperSlide>
                                        <div className='flex-c-m flex-col txt-white pointer' onClick={() => navigate(`/wallet/withdrawal/${state.wallets[state.walletIndex].id}`, { state: { from: storePaths(location) } })}>
                                            <BiDollarCircle style={{ height: 28, width: 'auto' }} />
                                            <p className='p-t-5 fs-10 txt-upper inter-bold'>{t('wallet.withdraw')}</p>
                                        </div>
                                    </SwiperSlide>
                                    : null
                            }
                        </Swiper>
                    </div>
                    : null
            }

            {/* //////////////////////////       Transaction History       ////////////////////////////////////// */}
           
        </div>
            <div className='textfield-border'>
                <div className='textfield-bkg'>


                    <div className='flex-sb-m p-tb-20'>
                        <div>
                            <p className='txt-white'>{t('wallet.transactionHistory')}</p>
                        </div>
                        <Link component={RouterLink} to={state.pageIndex === 0 ? `/wallet/transactionHistory/${state.wallets[state.walletIndex]?.id}` : `/commission/transactionHistory/${state.commissions[state.commissionIndex]?.id}`} state={{ from: storePaths(location) }} underline='none'>
                            <div>
                                <p className='txt-white pointer'>{t('general.viewAll')}</p>
                            </div>
                        </Link>
                    </div>
                    {
                        _.size(state?.wallets[state.walletIndex]?.transaction) > 0 ?
                            <>
                                {
                                    _.map(state.wallets[state.walletIndex].transaction, (detail, index) => {
                                        return (
                                            <div key={index} >
                                                <div className={index !== 0 ? 'divider-dark' : ''}></div>
                                                <div className='pointer p-tb-15 flex-sb-m' style={{ padding: '16px 0', }} onClick={() => handleClickOpenDialog(detail)} >
                                                    <div className='txt-white' style={{ width: 260, maxWidth: '50vw' }}>
                                                        <p className='fs-16' style={{ fontWeight: 600 }}>{detail?.transaction_code_display.details[i18n.resolvedLanguage]}{detail?.transaction_type == 'withdraw' ? displayStatus(detail?.wallet_transactionable?.status || 0) : null}</p>
                                                        <p className='p-t-3 fs-12 cl-label '>{detail.created_at}</p>
                                                    </div>
                                                    <div className='txt-white txt-right' style={{ width: 100 }}>
                                                        <p className='fs-16' style={{ color: detail.factor === 1 ? '#5de1e6' : '#CB6CE6', fontWeight: 600 }}>{detail.factor === 1 ? '+ ' : '- '}{currencyFormat(detail.amount * 1, state.walletDecimal)}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    _.size(state?.wallets[state.walletIndex]?.transaction) >= 10 ?
                                        <Link component={RouterLink} to={`/wallet/transactionHistory/${state.wallets[state.walletIndex].id}`} state={{ from: storePaths(location) }} underline='none' style={{ textAlign: 'center' }}>
                                            <div className='p-t-15 txt-upper'>
                                                <p className='txt-white pointer'>{t('general.viewAll')}</p>
                                            </div>
                                        </Link> : null
                                }
                            </>
                            :
                            <div className='txt-center' style={{ paddingTop: 40 }}>
                                <img src='/images/general/no-transaction.png' alt='no-transaction' style={{ width: 180 }} loading='lazy' />
                                <p className='txt-white fs-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('table.noRecord')}</p>
                            </div>
                    }
                  
                </div>
            </div>
            <TransactionHistoryDialog dialog={dialog}  handleCloseDialog={handleCloseDialog} />
        </div>

        
    )
}

const useStyles = makeStyles(theme => ({
    funcButton: {
        background: 'linear-gradient(90deg, #55D3FF,#684EFF)',
        padding: 2,
        marginX: 10,
        width: 64,
        height: 64,
        flexDirection: 'column',
        zIndex: 1,
        borderRadius: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        color: '#ffffff'
    }
}));